import "../components/ServicePanel/ServicePanel"
import React, {useEffect, useState} from "react";
import ServicePanel from "../components/ServicePanel/ServicePanel";
import {Input} from "../components/ui/input";
import {BaseView} from "./BaseView";
import axios from 'utility/customAxios';
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {SearchIcon, XIcon} from "lucide-react";
import {cn} from "../components/ui/lib/utils";
import {useSearchParams} from "react-router-dom";
import {NamespaceSelector} from "../components/NamespaceSelector";
import {Label} from "../components/ui/label";
import {Switch} from "../components/ui/switch";

interface ServiceSummary {
    displayName: string,
    serviceName: string,
    numAnomalies: number,
    numAlerts: number,
    serviceP50Nanos: number,
    serviceP95Nanos: number,
    serviceP99Nanos: number,
    service500s: number,
    service400s: number,
    requestCount: number
}

export interface ServiceSummaries {
    serviceSummaries: ServiceSummary[]
}


export function SearchBar(props: {
    filter: string,
    setFilter: (filter: string) => void
    setAlerting: (alerting: boolean) => void
    setUnhealthy: (unhealthy: boolean) => void
    searchPlaceholder?: string
    hideNamespaceSelector?: boolean
    hideUnhealthyToggle?: boolean
}) {
    return <div className={"shrink h-full flex min-w-0 pb-2 gap-4 items-center"}>
        <div
            className={"bg-backgroundmedium grow shrink flex-none h-12 px-[7px] py-[9px] hover:cursor-pointer hover:border-primary rounded border justify-between items-center gap-2 inline-flex"}
        >
            <div
                className="w-max px-[7px] py-[9px] justify-start items-center gap-2 inline-flex">
                <div className="w-6 h-6 relative hover:cursor-pointer hover:text-primary text-border"><SearchIcon/>
                </div>
            </div>
            <div className={"w-full"}>
                <Input placeholder={props.searchPlaceholder ? props.searchPlaceholder : "Search service"}
                       id={"free_text_input"} onChangeCapture={(e) => {
                    props.setFilter(e.currentTarget.value);
                }}
                       className={cn("h-7 text-textlight bg-backgroundmedium ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 w-full")}/>
            </div>
            <div onClick={
                () => {
                    const input = document.getElementById("free_text_input") as HTMLInputElement;
                    if (input) {
                        input.value = "";
                    }
                    props.setFilter("");
                }
            } className="w-6 h-6 relative text-textmedium hover:bg-primary rounded hover:cursor-pointer">
                <XIcon/>
            </div>
        </div>
        {!props.hideNamespaceSelector && <div className={"flex h-full flex-none justify-end space-x-2 text-textmedium"}>
            <div className="flex h-full items-center space-x-2">
                <NamespaceSelector/>
            </div>
        </div>}
        {!props.hideUnhealthyToggle && <div className={"flex h-max flex-none justify-end space-x-2 text-textmedium"}>
            {/*<div className="flex max-h-max items-center space-x-2">*/}
            {/*    <Switch id="alerting" onCheckedChange={isChecked => props.setAlerting(isChecked)}/>*/}
            {/*    <Label htmlFor="alerting">Alerting</Label>*/}
            {/*</div>*/}
            <div className="flex h-max items-center space-x-2">
                <Switch id="unhealthy" onCheckedChange={isChecked => props.setUnhealthy(isChecked)}/>
                <Label htmlFor="unhealthy">Unhealthy</Label>
            </div>
        </div>}
    </div>
}

const ServiceCatalogue: React.FC = () => {
    const [data, setData] = useState<ServiceSummaries>();
    const [filter, setFilter] = useState<string>("");
    const [alerting, setAlerting] = useState<boolean>();
    const [unhealthy, setUnhealthy] = useState<boolean>();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [namespace, setNamespace] = useState<string>("");
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null) {
            setEnvironments([environments])
        }
        let namespace = searchParams.get("namespace");
        if (namespace !== null) {
            setNamespace(namespace)
        }
    }, [searchParams])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const startEnd = timeRange.getStartEnd()
                const d = axios.post("/api/v1/serviceSummaries", {
                        "startTime": Math.floor(startEnd[0].getTime() / 1000),
                        "endTime": Math.floor(startEnd[1].getTime() / 1000),
                        "environments": environments[0] === "" ? [] : environments,
                        "namespace": namespace
                    }
                );
                setData((await d).data);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [timeRange, environments, namespace]);

    let serviceSummaries: ServiceSummary[] = [];

    if (data && data.serviceSummaries) {
        serviceSummaries = data.serviceSummaries
            .filter(x => x.serviceName.toLowerCase().includes(filter.toLowerCase()))
            .filter(x => alerting ? x.numAlerts > 0 : true)
            .filter(x => unhealthy ? (
                x.numAnomalies > 0 || 
                x.numAlerts > 0 || 
                (x.service500s / x.requestCount * 100) > 2
            ) : true);
    }
    return (
        <BaseView title={"Service Catalog"}>
            <div className={"mt-4 flex flex-col min-w-0 min-h-0 grow shrink"}>
                <div className={"px-4"}>
                    <SearchBar filter={filter} setFilter={setFilter} setAlerting={setAlerting}
                               setUnhealthy={setUnhealthy}/>
                </div>
                {data && data.serviceSummaries &&
                    <div className={"p-4 flex overflow-y-auto justify-center w-full min-w-0 min-h-0"}>
                        <div
                            className="min-h-0 min-w-0  grid gap-4 2xl:grid-cols-4 2xl:max-w-[2000px] xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 w-full">
                            {
                                serviceSummaries
                                    .map((service, index) => {
                                        return <ServicePanel key={index} {...service} />
                                    })}
                        </div>
                    </div>
                }
                {
                    serviceSummaries.length === 0 &&
                    <div className={"flex justify-center"}>
                        <div className={"text-xl text-slate-500 font-semibold"}>
                            No services found
                        </div>
                    </div>
                }
            </div>
        </BaseView>
    )
}


export default ServiceCatalogue;
