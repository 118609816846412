import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import {GuardProps} from 'types/auth';
import {ROUTES} from "../resources/routes-constants";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";

const GuestGuard = ({children}: GuardProps) => {
    const {isLoggedIn} = useAuth();
    const navigate = usePreserveQueryParamsNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            navigate(location?.state?.from ? location?.state?.from : ROUTES.HOMEPAGE_ROUTE, {
                state: {
                    from: ''
                },
                replace: true
            });
        }
    }, [isLoggedIn, navigate, location]);

    return children;
};

export default GuestGuard;
