import {XIcon} from "lucide-react";
import {cn} from "../ui/lib/utils";
import React, {useEffect, useRef} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandGroup, CommandItem, CommandList} from "../ui/command";
import {Attribute} from "../../types/telemetry";
import humanFormat from "human-format";


export function GroupByPill(props: {
    attributeKey: string,
    groupBy: string[],
    setGroupBy: (groupBy: string[]) => void
}) {
    return <div className="group justify-start items-start flex hover:cursor-pointer">
        <div
            className="p-2 bg-backgrounddark rounded-tl rounded-bl border justify-start items-start flex">
            <div
                className="text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]">{props.attributeKey}
            </div>
        </div>
        <div
            onClick={() => {
                const newGroupBy = props.groupBy.filter((value) => value !== props.attributeKey);
                props.setGroupBy(newGroupBy);
            }}
            className="h-8 pt-[3px] bg-backgroundlight hover:bg-primary hidden group-hover:block group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textmedium">
            <XIcon/>
        </div>
    </div>;
}

function handleFilterUpdate(props: {
    key?: string;
    attributeKey: string;
    attributeValue: string;
    filter: Map<string, string[]>;
    setFilter: (filter: Map<string, string[]>) => void;
    isExclude?: boolean;
    excludeFilter?: Map<string, string[]>;
    setExcludeFilter?: (filter: Map<string, string[]>) => void;
    isEditable?: boolean;
    regexes?: string[];
    setRegexes?: (regexes: string[]) => void;
    excludeRegexes?: string[];
    setExcludeRegexes?: (regexes: string[]) => void;
    isRegex?: boolean;
    additionalRemoveAction?: () => void;
    dropDownFunction?: (key: string) => Promise<Attribute[]>
}, value: string) {
    if (props.isRegex) {
        let newRegexes = props.isExclude ? [...props.excludeRegexes!] : [...props.regexes!];
        // Replace the old value with the new value
        const index = newRegexes.indexOf(props.attributeValue);
        newRegexes[index] = value;
        if (props.isExclude) {
            props.setExcludeRegexes!(newRegexes);
            return;
        } else {
            props.setRegexes!(newRegexes);
            return;
        }
    }

    const newFilter = props.isExclude ? new Map(props.excludeFilter!) : new Map(props.filter);
    const parsedValue = value.split(" || ");
    newFilter.set(props.attributeKey, parsedValue);
    if (props.isExclude) {
        props.setExcludeFilter!(newFilter);
        return
    } else {
        props.setFilter(newFilter);
        return
    }
}

export function Pill(props: {
    key?: string,
    attributeKey: string,
    attributeValue: string,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void
    isExclude?: boolean
    excludeFilter?: Map<string, string[]>
    setExcludeFilter?: (filter: Map<string, string[]>) => void
    isEditable?: boolean
    regexes?: string[]
    setRegexes?: (regexes: string[]) => void
    excludeRegexes?: string[]
    setExcludeRegexes?: (regexes: string[]) => void
    isRegex?: boolean
    additionalRemoveAction?: () => void
    dropDownFunction?: (key: string) => Promise<Attribute[]>
}) {
    const valueRef = useRef(null);
    const [dropDownValues, setDropDownValues] = React.useState<Attribute[]>([]);

    useEffect(() => {
        if (valueRef?.current && props.attributeValue === "") {
            // @ts-ignore
            valueRef.current.focus();
        }
    }, [valueRef, props.attributeValue]);

    const [isOpened, setIsOpened] = React.useState(false);
    const [isValueInputFocused, setIsValueInputFocused] = React.useState(false);
    const [isCommandFocused, setCommandFocused] = React.useState(false);
    const [value, setValue] = React.useState("");
    const [valueCursorPosition, setValueCursorPosition] = React.useState(0);

    useEffect(() => {
        if (!isValueInputFocused) {
            return
        }
        if (!props.dropDownFunction) {
            return
        }
        props.dropDownFunction(props.attributeKey).then((values) => {
            setDropDownValues(values);
        })
    }, [isValueInputFocused, props.attributeKey]);

    return <div className="group justify-start items-start flex hover:cursor-pointer max-w-full">
        <div
            className={cn("bg-backgrounddark rounded-tl rounded-bl justify-start items-start flex border-t border-l border-b")}
            onClick={() => {
                if (valueRef?.current) {
                    // @ts-ignore
                    valueRef.current.focus();
                }
            }}>
            {
                props.isEditable && !props.isRegex &&
                <div contentEditable={true} key={props.key} id={"key_input"}
                     onClick={(e) => {
                         e.stopPropagation();
                     }}

                     onKeyDown={(e) => {
                         // if the return key is pressed, blur the input
                         if (e.key === "Enter") {
                             e.preventDefault();
                             e.currentTarget.blur();
                             return
                         }
                     }}
                     onBlur={(e) => {
                         let key = e.currentTarget.textContent!;
                         key = key.replace(/\n/g, "");
                         const newFilter = props.isExclude ? new Map(props.excludeFilter!) : new Map(props.filter);
                         const prevValue = props.isExclude ? props.excludeFilter!.get(props.attributeKey) : props.filter.get(props.attributeKey);
                         newFilter.delete(props.attributeKey);
                         newFilter.set(key, prevValue || []);
                         if (props.isExclude) {
                             props.setExcludeFilter!(newFilter);
                             return
                         } else {
                             props.setFilter(newFilter);
                             return
                         }
                     }}
                     className={cn("h-[30px] hover:cursor-text p-2 w-max max-w-max flex-none text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}>{props.attributeKey}</div>
            }
            {
                (!props.isEditable || props.isRegex) &&
                <div
                    className={cn("p-2 text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}>{props.attributeKey}
                </div>
            }
        </div>

        <Popover open={isOpened}>
            <PopoverTrigger>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpened(!isOpened)
                }
                }
                     className={cn("p-2 rounded-tl rounded-bl border justify-start items-start flex text-white", props.isExclude ? "bg-redtransparenter border-red-500" : "bg-secondarytransparenter border-secondary")}>
                    <div
                        className={cn("text-center text-sm font-medium font-['Inter'] leading-[14px] text-textlight")}>{props.isExclude ? "!=" : "="}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent
                onInteractOutside={() => {
                    setIsOpened(false)
                }}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setIsOpened(false)
                    }
                }}
                className={"bg-backgroundmedium border rounded text-textmedium"}>
                <Command>
                    <CommandList>
                        <CommandGroup>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                onSelect={() => {
                                    if (!props.isExclude) {
                                        // Do nothing if the filter already exists
                                        return
                                    }

                                    if (props.isRegex) {
                                        const excludes = props.excludeRegexes!.filter((value) => value !== props.attributeValue);
                                        props.setRegexes!(props.regexes!.concat(props.attributeValue));
                                        props.setExcludeRegexes!(excludes);
                                        return
                                    }

                                    // Remove from the exclude filter if it exists and add to the filter
                                    const newFilter = new Map(props.filter);
                                    const excludeFilter = new Map(props.excludeFilter!);
                                    const value = excludeFilter.get(props.attributeKey);
                                    excludeFilter.delete(props.attributeKey);
                                    newFilter.set(props.attributeKey, value!);
                                    props.setExcludeFilter!(excludeFilter);
                                    props.setFilter(newFilter);
                                }}

                            >=</CommandItem>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                onSelect={() => {
                                    if (props.isExclude) {
                                        // Do nothing if the filter already exists
                                        return
                                    }

                                    if (props.isRegex) {
                                        const regexes = props.regexes!.filter((value) => value !== props.attributeValue);
                                        props.setExcludeRegexes!(props.excludeRegexes!.concat(props.attributeValue));
                                        props.setRegexes!(regexes);
                                        return
                                    }

                                    // Remove from the filter if it exists and add to the exclude filter
                                    const newFilter = new Map(props.filter);
                                    const excludeFilter = new Map(props.excludeFilter!);
                                    const value = newFilter.get(props.attributeKey);
                                    newFilter.delete(props.attributeKey);
                                    // If there's already an exclude filter for this key, append the value to the array
                                    const prevExcludeValue = excludeFilter.get(props.attributeKey);
                                    if (prevExcludeValue) {
                                        excludeFilter.set(props.attributeKey, prevExcludeValue.concat(...value!));
                                    } else {
                                        excludeFilter.set(props.attributeKey, value!);
                                    }
                                    props.setFilter(newFilter);
                                    props.setExcludeFilter!(excludeFilter);
                                }}>!=</CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>

        <Popover open={(isValueInputFocused || isCommandFocused) && dropDownValues.length > 0 && dropDownValues.filter(
            (key) => value.length == 0 || key.value.toLowerCase().includes(getCurrentSegmentValue(value, valueCursorPosition).trim())).length > 0}
        >
            <PopoverContent
                onKeyDown={e => {
                    if (e.key !== "ArrowUp" && e.key !== "ArrowDown" && e.key !== "Enter" && e.key !== "Tab") {
                        document.getElementById("value_input_" + props.attributeKey)!.focus();
                    }
                }}
                onOpenAutoFocus={(e) => e.preventDefault()} align={"start"}
                className={"bg-backgroundmedium border rounded text-textmedium mt-[12px]"}>
                <Command
                    id={"inputpillpopovercontent"} onBlur={() => setCommandFocused(false)}>
                    <CommandList>
                        <CommandGroup>
                            {
                                dropDownValues && dropDownValues.filter(
                                    (key) => value.length == 0 || key.value.toLowerCase().includes(getCurrentSegmentValue(value, valueCursorPosition).trim())
                                ).map((key) => {
                                    return <CommandItem
                                        className={cn("w-full ariahover:cursor-pointer justify-between hover:bg-secondarytransparenter hover:border hover:border-secondary hover:text-textlight", isCommandFocused ? "aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight" : "")}
                                        onSelect={() => {
                                            // Get the current segment
                                            let cursorPosition = valueCursorPosition;
                                            let value = document.getElementById("value_input_" + props.attributeKey)!.textContent!;
                                            let currentSegment = getCurrentSegment(value, cursorPosition);
                                            let segments = value.split("||").map((segment) => segment.trim());
                                            segments[currentSegment] = key.value;
                                            let newValue = segments.join(" || ");
                                            handleFilterUpdate(props, newValue);
                                            document.getElementById("free_text_input")!.focus();
                                        }}>{key.value}
                                        <div
                                            className={"font-bold text-textdark"}>{key.volume !== 0 ? humanFormat(key.volume, {maxDecimals: 1}) : "-"}</div>
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
            <PopoverTrigger asChild={true}>
                <div
                    className={cn("w-max border-t border-b border-r rounded-r group-hover:border-t group-hover:border-b group-hover:border-r-0 group-hover:rounded-r-none justify-center items-center flex max-w-[500px]", " bg-backgroundmedium")}>
                    {
                        props.isEditable &&
                        <div
                            onClick={(e) => {
                                setValueCursorPosition(getCursorPosition(document.getElementById("value_input_" + props.attributeKey)));
                                e.stopPropagation();
                            }}
                            onFocus={(e) => {
                                setValueCursorPosition(getCursorPosition(document.getElementById("value_input_" + props.attributeKey)));
                                setIsValueInputFocused(true);
                            }}
                            onBlur={(e) => {
                                setIsValueInputFocused(false);
                            }}

                            onSelect={(e) => {
                                setValueCursorPosition(getCursorPosition(document.getElementById("value_input_" + props.attributeKey)));
                            }}

                            contentEditable={true} key={props.key}
                            onKeyUp={(e) => {
                                setValue(e.currentTarget.textContent!);
                            }}
                            onKeyDown={(e) => {
                                setValueCursorPosition(getCursorPosition(document.getElementById("value_input_" + props.attributeKey)));
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    setCommandFocused(true);
                                    document.getElementById("inputpillpopovercontent")!.focus();
                                }
                                // if the return or tab key is pressed, blur the input
                                if (e.key === "Enter" || e.key === "Tab") {
                                    e.preventDefault();
                                    handleFilterUpdate(props, value.replace(/\n/g, ""));
                                    e.currentTarget.blur();
                                    setIsValueInputFocused(false);
                                    return
                                }
                            }}
                            ref={valueRef}
                            id={"value_input_" + props.attributeKey}
                            className={cn("h-[30px] hover:cursor-text p-2 w-max flex-none text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px] text-nowrap overflow-x-auto overflow-y-hidden no-scrollbar max-w-full")}>{props.attributeValue}</div>
                    }
                    {
                        !props.isEditable &&
                        <div
                            id={"value_input_" + props.attributeKey}
                            className={cn("p-2 text-left text-textlight text-sm font-medium font-['Inter'] leading-[14px] text-nowrap overflow-x-auto overflow-y-hidden no-scrollbar")}>{props.attributeValue}
                        </div>
                    }
                </div>
            </PopoverTrigger>
        </Popover>
        <div
            onClick={() => {
                if (props.isRegex) {
                    const newRegexes = props.isExclude ? props.excludeRegexes!.filter((value) => value !== props.attributeValue) : props.regexes!.filter((value) => value !== props.attributeValue);
                    if (props.isExclude) {
                        props.setExcludeRegexes!(newRegexes);
                    } else {
                        props.setRegexes!(newRegexes);
                    }
                    return
                }

                if (props.isExclude) {
                    const newFilter = new Map(props.excludeFilter!);
                    newFilter.delete(props.attributeKey);
                    props.setExcludeFilter!(newFilter);
                    return
                } else {
                    const newFilter = new Map(props.filter);
                    newFilter.delete(props.attributeKey);
                    props.setFilter(newFilter);
                }
                if (props.additionalRemoveAction) {
                    props.additionalRemoveAction();
                }
            }}
            className="h-8 pt-[3px] bg-backgroundmedium hover:bg-primary hidden group-hover:block  group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textlight">
            <XIcon/>
        </div>
    </div>;
}

function getCurrentSegmentValue(s: string, cursorPosition: number) {
    const segments = s.split("||");
    let pos = 0;
    for (let i = 0; i < segments.length; i++) {
        if (pos + segments[i].length >= cursorPosition) {
            return segments[i];
        }
        pos += segments[i].length;
    }
    return segments[segments.length - 1];
}

function getCurrentSegment(s: string, cursorPosition: number) {
    const segments = s.split("||").map((segment) => segment.trim());
    let pos = 0;
    for (let i = 0; i < segments.length; i++) {
        if (pos + segments[i].length >= cursorPosition) {
            return i;
        }
        pos += segments[i].length;
    }
    return segments.length - 1;
}

function getSegmentsStartPositions(s: string) {
    const segments = s.split("||");
    let positions = [];
    let pos = 0;
    for (let i = 0; i < segments.length; i++) {
        positions.push(pos);
        pos += segments[i].length;
    }
    return positions;
}

function getCursorPosition(element: any) {
    var caretOffset = 0;

    if (window.getSelection) {
        if (window.getSelection()!.rangeCount == 0) {
            return 0;
        }
        var range = window.getSelection()!.getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
}

function handleFilterCreation(s: string, props: {
    placeholderText: string;
    filter: Map<string, string[]>;
    setFilter: (filter: Map<string, string[]>) => void;
    excludeFilter?: Map<string, string[]>;
    setExcludeFilter?: (filter: Map<string, string[]>) => void;
    regexes?: string[];
    setRegexes?: (regexes: string[]) => void;
    inputKeys?: string[]
}) {
    // If there is a ! in the last character, create an exclude filter
    let isExclude = false;
    if (s.charAt(s.length - 2) === "!") {
        isExclude = true;
        s = s.slice(0, -2);
    } else {
        s = s.slice(0, -1);
    }
    if (isExclude) {
        const newFilter = new Map(props.excludeFilter!);
        let prevValue = newFilter.get(s);
        if (prevValue) {
            newFilter.set(s, prevValue.concat(""));
        } else {
            newFilter.set(s, [""]);
        }
        props.setExcludeFilter!(newFilter);
        if (prevValue) {
            setTimeout(() => {
                document.getElementById("value_input_" + s)!.focus();
                // select all the content in the element
                document.execCommand('selectAll', false, undefined);
// collapse selection to the end
                document.getSelection()!.collapseToEnd();
            }, 100);
        }
    } else {
        const newFilter = new Map(props.filter);
        let prevValue = newFilter.get(s);
        if (prevValue) {
            newFilter.set(s, prevValue.concat(""));
        } else {
            newFilter.set(s, [""]);
        }
        props.setFilter(newFilter);
        if (prevValue) {
            setTimeout(() => {
                document.getElementById("value_input_" + s)!.focus();
                document.execCommand('selectAll', false, undefined);
// collapse selection to the end
                document.getSelection()!.collapseToEnd();
            }, 100);
        }
    }
}

export function InputPill(props: {
    placeholderText: string,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter?: Map<string, string[]>,
    setExcludeFilter?: (filter: Map<string, string[]>) => void
    regexes?: string[],
    setRegexes?: (regexes: string[]) => void
    inputKeys?: string[]
}) {
    const [isFocused, setIsFocused] = React.useState(false);
    const [commandFocused, setCommandFocused] = React.useState(false);
    const [value, setValue] = React.useState("");

    return <div
        className={cn("grow shrink justify-start items-start flex hover:cursor-pointer")}>
        <div
            className={cn("h-8 border-t bg-backgroundlight border-b border-r rounded-r group-hover:border-r-0 group-hover:rounded-r-none self-stretch justify-center items-center flex", "border-none bg-backgoundmedium w-full")}>
            <div className={cn("w-full")}>
                <Popover open={(isFocused || commandFocused) && props.inputKeys && props.inputKeys.length > 0 && props.inputKeys.filter((key) => key.toLowerCase().includes(value)).length !== 0}>
                    <PopoverContent
                        onKeyDown={e => {
                            if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
                                document.getElementById("free_text_input")!.focus();
                            }
                        }}
                        onOpenAutoFocus={(e) => e.preventDefault()} align={"start"}
                        className={"bg-backgroundmedium border rounded text-textmedium mt-[12px]"}>
                        <Command
                            id={"inputpillpopovercontent"} onBlur={() => setCommandFocused(false)}>
                            <CommandList>
                                <CommandGroup>
                                    {
                                        props.inputKeys && props.inputKeys!.filter(
                                            (key) => key.toLowerCase().includes(value)
                                        ).map((key) => {
                                            return <CommandItem
                                                className={cn("w-full ariahover:cursor-pointer hover:bg-secondarytransparenter hover:border hover:border-secondary hover:text-textlight ", commandFocused ? "aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight" : "")}
                                                onSelect={() => {
                                                    document.getElementById("free_text_input")!.textContent = "";
                                                    setValue("");
                                                    handleFilterCreation(key + "=", props);
                                                }}>{key}</CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                    <PopoverTrigger asChild={true}>
                        <div contentEditable={true} id={"free_text_input"} onFocus={(e) => {
                            setIsFocused(true);
                        }}
                             onKeyUp={(e) => {
                                 setValue(e.currentTarget.textContent!);
                                 // If up or down arrow is pressed, focus on the command
                                 if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                     setCommandFocused(true);
                                     document.getElementById("inputpillpopovercontent")!.focus();
                                 }
                                 if (e.key === "Enter" || e.key === "Tab") {
                                     e.preventDefault();
                                     if (e.currentTarget.textContent === "") {
                                         return
                                     }
                                     let search = e.currentTarget.textContent!;
                                     search = search.replace(/\n/g, "");
                                     // Add a regex
                                     const newRegexes = props.regexes!.concat(search);
                                     props.setRegexes!(newRegexes);
                                     e.currentTarget.textContent = "";
                                     setValue("");
                                     return
                                 }

                                 if (e.key === "Escape") {
                                     setIsFocused(false);
                                 }

                                 // If the = key is pressed, create a filter instead of typing the character
                                 if (e.key !== "=") {
                                     return
                                 }
                                 let search = e.currentTarget.textContent!;
                                 setValue("");
                                 document.getElementById("free_text_input")!.textContent = "";
                                 handleFilterCreation(search, props);
                             }}
                             onBlur={(e) => {
                                 setIsFocused(false);
                             }}
                             className={cn("h-[30px] flex hover:cursor-text p-2 grow shrink flex-none text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}></div>
                    </PopoverTrigger>
                </Popover>
            </div>
        </div>
    </div>;
}