import React from "react";
import {Label} from "components/ui/label";
import {Input} from "components/ui/input";
import {Button} from "components/ui/button";
import {Icons} from "components/ui/icons";
import {Formik} from "formik";
import * as Yup from 'yup';
import useAuth from "hooks/useAuth";
import useScriptRef from "hooks/useScriptRef";
import {Alert, AlertDescription, AlertTitle} from "components/ui/alert";
import {ExclamationTriangleIcon} from "@radix-ui/react-icons";
import {useCookies} from "react-cookie";

interface EmailAuthLoginProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function EmailAuthLogin({className, ...props}: EmailAuthLoginProps) {
    const {login} = useAuth();
    const scriptedRef = useScriptRef();

    const [cookies, setCookie] = useCookies(['aws_marketplace_customer_id']);
    const awsCustomerIdToken = cookies.aws_marketplace_customer_id ? cookies.aws_marketplace_customer_id as string: "";

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email. ').max(255).required('Email is required. '),
                password: Yup.string().max(255).required('Password is required. ')
            })}
            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                try {
                    await login(values.email, values.password, awsCustomerIdToken);
                    if (scriptedRef.current) {
                        setStatus({success: true});
                        setSubmitting(false);
                    }
                } catch (err: any) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({success: false});

                        let errorMessage = err.message;
                        if (err.response) {
                            errorMessage = JSON.stringify(err.response.data);
                        }

                        setErrors({submit: errorMessage});
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <div className="grid gap-2">
                        <div className="grid gap-2">
                            <div className="grid gap-2">
                                <Label className={"text-textmedium"} htmlFor="email">Email</Label>
                                <Input className={"rounded border border-border"} onChange={handleChange} value={values.email} id="email" type="email"
                                       placeholder="me@example.com"/>
                            </div>
                            <div className="grid gap-2">
                                <Label className={"text-textmedium "} htmlFor="password">Password</Label>
                                <Input className={"rounded border-border"} onChange={handleChange} id="password" type="password" value={values.password}
                                       placeholder={"supersecret123"}/>
                            </div>
                        </div>
                        <Button type="submit" className={"mt-4 size-full text-base text-textlight border border-primary rounded bg-primarytransparent hover:bg-primaryhover"} disabled={isSubmitting}>
                            {isSubmitting && (
                                <Icons.Spinner className="mr-2 h-4 w-4 animate-spin"/>
                            )}
                            Sign In with Email
                        </Button>

                        {
                            ((touched.email && errors.email) || errors.submit || (touched.password && errors.password)) && (
                                <Alert variant="destructive"  className="mt-4">
                                    <ExclamationTriangleIcon className="text-red-500 h-4 w-4"/>
                                    <AlertTitle className={"text-red-500"}>Error</AlertTitle>
                                    <AlertDescription className={"text-red-500"}>
                                        {errors.email}
                                        {errors.password}
                                        {errors.submit}
                                    </AlertDescription>
                                </Alert>)
                        }
                    </div>
                </form>
            )}
        </Formik>
    )

}