import React from 'react'
import {Router} from "react-router";
import './styles/main.sass'
import Routes from 'routes';
import {BrowserHistory, createBrowserHistory} from "history";


const rawHistory = createBrowserHistory();

export {
    rawHistory
}

type Props = {
    basename?: string;
    children: React.ReactNode;
    history: BrowserHistory;
}

const CustomRouter = ({ basename, children, history }: Props) => {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState),[history])

    return (
        <Router
            basename={basename}
            location={state.location}
            navigator={history}
            navigationType={state.action}
        >
            {children}
        </Router>
    );
};

const RootComponent: React.FC = () => {
    return (
        <CustomRouter history={rawHistory}>
            <Routes/>
        </CustomRouter>
    )
}

export default RootComponent
