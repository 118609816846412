import {ClockIcon, MilestoneIcon} from "lucide-react";
import {Tag} from "components/SidePanel/Tags/TagContainer";
import {shortEnglishHumanizer} from "../../ServicePanel/ServicePanel";
import {format} from "date-fns";
import React from "react";
import {getIndicatorColorForStatusCode} from "../../../pages/Traces";

export function TraceIdTitle(props: {
    serviceName: string
    method: string,
    path: string,
    traceId: string,
}) {
    return <div className="w-[350px] h-full flex-col justify-start items-start inline-flex">
        <div className="justify-start items-center gap-1 inline-flex">
            <MilestoneIcon className="text-secondary w-8 h-[42px] relative"/>
            <div className="pb-[8px] self-stretch justify-start items-center gap-2 flex">
                <div className="text-textdark text-sm font-semibold leading-tight text-nowrap">{props.serviceName}</div>
                <div className="text-blue-500 text-sm font-semibold leading-tight">&gt;&gt;</div>
                <div
                    className="text-textdark text-sm font-semibold leading-tight text-nowrap overflow-x-clip">{props.method.toUpperCase() + "  " + props.path}</div>
            </div>
        </div>
        <Tag tagKey={"traceId"} tagValue={props.traceId} borderColour={"border-secondary"}/>
    </div>
}

export function TraceRequestResultDetails(props: {
    datetime: number,
    statusCode: string,
    statusMessage: string,
    duration: number,
}) {
    const indicatorColor = getIndicatorColorForStatusCode(props.statusCode);
    return (
    <div className="w-full justify-end items-center gap-4 inline-flex">
        <div className={`px-1 py-2 ${indicatorColor} rounded justify-center items-center flex`}>
            <div className="text-textlight text-sm font-medium leading-[14px]">{`${props.statusCode} ${props.statusMessage}`}</div>
        </div>
        <div className="text-textdark text-sm font-medium leading-[14px]">|</div>
        <div className="justify-start items-center gap-1 flex">
            <ClockIcon className="w-6 h-6 text-textdark relative"/>
            <div className="text-textdark text-sm font-medium leading-[14px]">{shortEnglishHumanizer(props.duration / 1_000_000)}</div>
        </div>
        <div className="text-textdark text-sm font-medium leading-[14px]">|</div>
        <div className="text-textdark text-sm font-medium leading-[14px]">{format(new Date(props.datetime), "PPpp")}</div>
    </div>
    );
}

