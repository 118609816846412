
import {format} from "date-fns";
import {DateTimeFormat, DateTimeFormatToLocale, ISO8601} from "../pages/Settings";
import {useLocale} from "react-aria";

export enum DateTimePrecision {
    Milliseconds = ".SSS",
    Seconds = "ss",
    Minutes = "mm",
    Hours = "HH",
    Day = "dd",
    Month = "MM",
    Year = "YYYY"
}

export function getPreferredFormatString(precision: DateTimePrecision[]) {
    let preferredFormatStr = localStorage.getItem("dateTimeFormat")

    // If not defined use ISO format as default.
    if (preferredFormatStr == null) {
        preferredFormatStr = DateTimeFormat.DDMMYYYY
    }

    if (!precision.includes(DateTimePrecision.Year)) {
        preferredFormatStr = preferredFormatStr.replace("yyyy/", "")
        preferredFormatStr = preferredFormatStr.replace("/yyyy", "")
    }

    if (!precision.includes(DateTimePrecision.Month)) {
        preferredFormatStr = preferredFormatStr.replace("MM/", "")
        preferredFormatStr = preferredFormatStr.replace("/MM", "")
    }

    if (!precision.includes(DateTimePrecision.Day)) {
        preferredFormatStr = preferredFormatStr.replace("dd/", "")
        preferredFormatStr = preferredFormatStr.replace("/dd", "")
    }

    if (!precision.includes(DateTimePrecision.Hours)) {
        preferredFormatStr = preferredFormatStr.replace("HH:", "")
    }

    if (!precision.includes(DateTimePrecision.Minutes)) {
        preferredFormatStr = preferredFormatStr.replace("mm:", "")
    }

    if (!precision.includes(DateTimePrecision.Seconds)) {
        preferredFormatStr = preferredFormatStr.replace("ss", "")
    }

    if (!precision.includes(DateTimePrecision.Milliseconds)) {
        preferredFormatStr = preferredFormatStr.replace(".SSS", "")
    }

    if (preferredFormatStr.charAt(preferredFormatStr.length - 1) == ":") {
        preferredFormatStr = preferredFormatStr.slice(0, -1)
    }

    return preferredFormatStr;
}

export function displayDateTimeWithSelectedFormat(
    dateTime: Date,
    precision: DateTimePrecision[],
): string {
    let preferredFormat = getPreferredFormatString(precision);

    return format(new Date(dateTime), preferredFormat)
}


export function getSelectedLocaleString() {
    let preferredFormat = localStorage.getItem("dateTimeFormat")

    // If not defined use EU format as default.
    if (preferredFormat == null) {
        preferredFormat = DateTimeFormat.DDMMYYYY
    }

    const localeString = DateTimeFormatToLocale.get(preferredFormat as DateTimeFormat)
    if (localeString == null) {
        return "ja-JP" // Default to ISO like one.
    }

    return localeString
}