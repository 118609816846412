import React from "react";
import {Badge} from "../ui/badge";
import {V1ContainerStatus} from "@kubernetes/client-node/dist/gen/model/v1ContainerStatus";
import {Tag} from "../SidePanel/Tags/TagContainer";
import {V1ContainerState, V1EnvVar, V1Pod} from "@kubernetes/client-node";
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip";
import {K8sPodDetails} from "./model";
import {TriangleAlertIcon} from "lucide-react";
import hdate from "human-date";

function ContainerDetails(props: {
    podState: string
    podDetail: V1Pod
    containerDetails: V1ContainerStatus
    handleClickContainer: () => void
}) {
    function getContainerStateColour() {
        if (props.podState === "Deleted") {
            return {stateColour: "bg-gray-500", stateString: "Deleted"};
        }

        let stateColour = "bg-gray-500"
        let stateString = "Unknown"
        if (props.containerDetails.state?.running) {
            stateColour = "bg-secondarytransparent"
            stateString = "Running"
        } else if (props.containerDetails.state?.waiting) {
            stateColour = "bg-amber-500"
            stateString = "Waiting"
        } else if (props.containerDetails.state?.terminated) {
            stateColour = "bg-red-500"
            stateString = "Terminated"
        }
        return {stateColour, stateString};
    }

    function getContainerLastState() {
        if (props.containerDetails.lastState) {
            return props.containerDetails.lastState
        }
    }

    let {stateColour, stateString} = getContainerStateColour();
    let lastState = getContainerLastState();

    return (
        <div
            className={`w-full max-w-full h-12 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip`}
            onClick={props.handleClickContainer}>
            <div
                className="w-full px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip hover:cursor-pointer">
                <div
                    className="flex-none w-[128px] text-textmedium text-sm font-medium leading-[16px] truncate">
                    {props.containerDetails.name}
                </div>
                <div
                    className="flex-none w-[560px] overflow-hidden text-textlight text-sm font-medium leading-[16px]">
                    {<Tooltip>
                        <TooltipTrigger>
                            <Badge
                                className={"rounded bg-slate-700 truncate hover:cursor-auto py-1"}>{props.containerDetails.image}</Badge></TooltipTrigger><TooltipContent
                        className={"border bg-backgroundmedium text-textmedium"}>{props.containerDetails.image}</TooltipContent></Tooltip>}
                </div>
                <div
                    className="flex-none w-[120px] text-textlight text-sm font-medium leading-[16px] truncate">
                    {<Badge className={`rounded ${stateColour} text-textlight py-1`}>{stateString}</Badge>}
                </div>
                <div
                    className="flex-none w-[100px] text-textlight text-sm font-medium leading-[16px]">
                    <div className={"flex flex-rows space-x-2"}>
                        <div className={"flex flex-col justify-center"}>
                            {props.containerDetails.restartCount}
                        </div>
                        {lastState && <div className={"flex flex-col justify-center"}>
                            <StateAttentionInfo lastState={lastState}/>
                        </div>}
                    </div>

                </div>

                <div
                    className="text-textlight text-sm font-medium leading-[16px] justify-end flex grow shrink">
                    {<Badge
                        className={"rounded text-textlight hover:bg-primaryhover bg-primarytransparent border border-primary py-2"}>View</Badge>}
                </div>
            </div>
        </div>
    );
}

function StateAttentionInfo(props: { lastState: V1ContainerState }) {
    if (props.lastState.terminated) {
        return <Tooltip delayDuration={50}>
                <TooltipTrigger>
                    <TriangleAlertIcon className={"text-red-500 hover:text-red-700"}/>
                </TooltipTrigger>
                <TooltipContent side={"bottom"} className={"bg-backgroundmedium border rounded text-sm"}>
                    <div className={"flex flex-col justify-center space-y-2"}>
                        <div className={"text-textmedium"}>
                            This container was last terminated
                            at {hdate.prettyPrint(props.lastState.terminated.finishedAt, {showTime: true})}
                        </div>
                        <div className={"flex flex-row text-textmedium space-x-5"}>
                            <div className={"text-textmedium font-semibold"}>Reason:</div>
                            <div className={"text-textmedium"}> {props.lastState.terminated.reason}</div>
                        </div>
                        <div className={"flex flex-row text-textmedium space-x-2"}>
                            <div className={"text-textmedium font-semibold"}>Exit code:</div>
                            <div className={"text-textmedium"}> {props.lastState.terminated.exitCode}</div>
                        </div>
                    </div>
                </TooltipContent>
            </Tooltip>
    }
    return <div/>
}

function Containers(props: {
    podDetails: K8sPodDetails
    namespace: string
    podK8sObj: V1Pod
    podStatus: string
}) {
    const [currContainerDetails, setCurrContainerDetails] = React.useState<V1ContainerStatus>();
    const [showContainerDetails, setShowContainerDetails] = React.useState(false);

    const containers = props.podK8sObj.status?.containerStatuses
    const podName = props.podDetails.name

    let filters = new Map<string, string[]>();
    filters.set("container_id", [`/k8s/${props.namespace}/${podName}/${currContainerDetails?.name}`])

    return (
        <div className="w-full flex flex-none flex-col gap-4 overflow-x-hidden">
            <div
                className="bg-backgroundmedium flex-none border rounded w-full flex flex-col">
                <div
                    className="w-full flex-none px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 inline-flex">
                    <div className="w-[128px] flex-none h-[24px] text-textmedium">Container</div>
                    <div className="w-[560px] flex-none h-[24px] text-textmedium">Image</div>
                    <div className="w-[120px] flex-none h-[24px] text-textmedium">Status</div>
                    <div className="w-[100px] flex-none h-[24px] text-textmedium">Restarts</div>
                </div>
                <div
                    className="overflow-y-auto scrollMedium border-t rounded-b">
                    {containers && containers.map((container, index) => {
                        return <ContainerDetails podState={props.podStatus}
                                                 podDetail={props.podK8sObj} containerDetails={container}
                                                 handleClickContainer={() => {
                                                     setShowContainerDetails(true);
                                                     setCurrContainerDetails(containers[index]);
                                                 }}/>
                    })}
                </div>
            </div>

            {showContainerDetails && <div className={"min-w-0 min-h-0 overflow-y-auto"}>
                <div className={"flex flex-col space-y-4 min-w-0 min-h-0"}>
                    <ContainerDetailsTable podState={props.podStatus} podDetail={props.podK8sObj}
                                           containerDetails={currContainerDetails}/>
                </div>
            </div>}
        </div>
    )
}

function ContainerDetailsTable(props: {
    podState: string
    podDetail: V1Pod
    containerDetails: V1ContainerStatus | undefined
}) {
    if (props.containerDetails === undefined) {
        return <div
            className="w-full h-[256px] bg-backgroundmedium border rounded text-textdark flex items-center justify-center">
            No container details found :(
        </div>

    }

    function getContainerState() {
        if (props.podState === "Deleted") return "Deleted"
        if (props.containerDetails === undefined) return "Unknown"
        const state = props.containerDetails.state
        if (state?.running) {
            return "Running"
        } else if (state?.waiting) {
            return "Waiting"
        } else if (state?.terminated) {
            return "Terminated"
        } else {
            return "Unknown"
        }
    }

    let stateString = getContainerState();

    function getEnvValueFromRef(env: V1EnvVar) {
        if (env.value !== undefined) {
            return env.value
        } else if (env.valueFrom !== undefined) {
            if (env.valueFrom.secretKeyRef !== undefined) {
                return "[REDACTED]"
            } else if (env.valueFrom.fieldRef !== undefined) {
                // TODO: HACKKKK Use the API Version as well
                const fieldPath = env.valueFrom.fieldRef.fieldPath
                const valueFromPath = getFieldRefValueFromPath(fieldPath)
                return valueFromPath ? valueFromPath : "[n/a]"
            }
        } else {
            return "[n/a]"
        }
    }

    function getToolTipContentFromRef(env: V1EnvVar) {
        if (env.valueFrom !== undefined) {
            if (env.valueFrom.secretKeyRef !== undefined) {
                return "Secret key value is redacted"
            }
        }
        return undefined
    }

    function getFieldRefValueFromPath(fieldPath: string) {
        const pathParts = fieldPath.split('.');
        let value: any = props.podDetail;
        for (const part of pathParts) {
            value = value[part];
            if (value === undefined) {
                return undefined;
            }
        }
        return value;
    }

    return (
        <div
            className="w-full p-4 gap-4 flex flex-col grow shrink min-h-0 min-w-0 bg-backgroundmedium border rounded py-4">
            <div className={"text-textmedium text-lg"}>
                Container Details
            </div>
            <div className={"flex flex-col space-y-8 grow shrink min-h-0 min-w-0"}>
                <div className={"flex flex-row space-x-8 min-h-0"}>
                    <div className={"text-textmedium font-bold w-[128px] flex-none"}>Status</div>
                    <div className={"text-textmedium"}>{stateString}</div>
                </div>
                <div className={"flex flex-row space-x-8 overflow-hidden"}>
                    <div className={"text-textmedium font-bold w-[128px] flex-none"}>ImageID</div>
                    <div
                        className="flex-none text-textlight text-sm font-medium">
                        {<Tooltip>
                            <TooltipTrigger>
                                <Badge
                                    className={"rounded bg-slate-700 truncate hover:cursor-auto py-1"}>{props.containerDetails.imageID}</Badge></TooltipTrigger><TooltipContent
                            className={"border bg-backgroundmedium text-textmedium"}>{props.containerDetails.imageID}</TooltipContent></Tooltip>}
                    </div>
                </div>
                <div className={"flex flex-row space-x-8"}>
                    <div className={"text-textmedium font-bold w-[128px] flex-none"}>ImagePullPolicy</div>
                    <div className={"text-textmedium"}>{props.podDetail.spec?.containers[0].imagePullPolicy}</div>
                </div>
                <div className={"flex flex-col gap-4"}>
                    <div className={"text-textmedium font-bold w-[128px] flex-none"}>Environment</div>
                    <div
                        className={"text-textmedium rounded-sm justify-start gap-2 flex flex-col"}>{props.podDetail.spec?.containers[0].env?.sort((a, b) => a.name.localeCompare(b.name)).map((env) => {
                            return <Tag tagKey={env.name} tagValue={getEnvValueFromRef(env)}
                                        tooltipContent={getToolTipContentFromRef(env)}/>
                        }
                        // @ts-ignore
                    )}</div>
                </div>
                <div className={"flex flex-col gap-4"}>
                    <div className={"text-textmedium font-bold w-[128px] flex-none"}>Mounts</div>
                    <div
                        className={"text-textmedium rounded-sm justify-start items-center space-y-2 "}>{props.podDetail.spec?.containers.map((con) => {
                        return con.volumeMounts?.sort((a, b) => a.name.localeCompare(b.name)).map((vol) => {
                            return <Tag tagKey={vol.name} tagValue={vol.mountPath}/>
                        })
                    })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Containers;