"use client"

import * as React from "react"
import {Formik} from "formik";
import * as Yup from "yup";
import useAuth from "hooks/useAuth";
import {cn, usePreserveQueryParamsNavigate} from "components/ui/lib/utils";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {Label} from "components/ui/label";
import {Input} from "components/ui/input";
import {Icons} from "components/ui/icons";
import {Alert, AlertDescription, AlertTitle} from "components/ui/alert";
import {ExclamationTriangleIcon} from "@radix-ui/react-icons";
import {Button} from "components/ui/button";
import useScriptRef from "hooks/useScriptRef";
import {useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";

type UserAuthFormRegisterProps = React.HTMLAttributes<HTMLDivElement>

export function UserAuthFormRegister({className, ...props}: UserAuthFormRegisterProps) {
    const navigate = usePreserveQueryParamsNavigate();
    const {loginGoogleSSO, registerWithInviteLink, register} = useAuth();
    const scriptedRef = useScriptRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const inviteToken = searchParams.get("inviteToken") || undefined;
    const [cookies, setCookie] = useCookies(['aws_marketplace_customer_id']);
    const awsCustomerIdToken = cookies.aws_marketplace_customer_id ? cookies.aws_marketplace_customer_id as string: "";

    return (
        <div className={cn("w-full grid gap-6", className)} {...props}>
            <GoogleOAuthProvider
                clientId="822301410763-mlt2jbo7blucoek4d1vbm4efuc30gd16.apps.googleusercontent.com"
            >
                <GoogleLogin
                    containerProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                    text="signin_with"
                    logo_alignment={"left"}
                    theme="outline"
                    size="medium"
                    useOneTap={false}
                    type={"standard"}
                    onSuccess={async (credentialResponse) => {
                        try {
                            // No invite token.
                            await loginGoogleSSO(credentialResponse, inviteToken, awsCustomerIdToken);
                        } catch (err: any) {
                            console.error(err);

                            if (err.response && err.response.status === 403) {
                                alert("Your account needs to be whitelisted by one of our team members. Please get in touch and we will quickly do this for you.");
                            }
                        }
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </GoogleOAuthProvider>
            <div className="relative">
                <div className="absolute inset-0 flex items-center text-textmedium">
                    <span className="w-full border-t"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase text-textmedium">
                    <span className="bg-background px-2 text-muted-foreground text-textmedium">
                        Or
                    </span>
                </div>
            </div>
            <Formik
                initialValues={{
                    email: '',
                    name: '',
                    password: '',
                    organization: inviteToken ? 'Already Set' : "",
                    token: inviteToken,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    name: Yup.string().max(255).required('Name is required'),
                    password: Yup.string().max(255).required('Password is required'),
                    organization: Yup.string().max(255).required('Organization is required')
                })}
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                    try {
                        if (inviteToken) {
                            await registerWithInviteLink(values.email, values.password,  values.name, inviteToken);
                        } else {
                            await register(values.email, values.password, values.name, values.organization, awsCustomerIdToken);
                        }

                        if (scriptedRef.current) {
                            setStatus({success: true});
                            setSubmitting(false);

                            setTimeout(() => {
                                navigate('/login', {replace: true});
                            }, 1500);
                        }
                    } catch (err: any) {
                        console.error(err);

                        if (scriptedRef.current) {
                            setStatus({success: false});
                            if (err.response.status === 403) {
                                setErrors({submit: "Your email needs to be allowlisted by one of our team members. Please get in touch and we will quickly do this for you."})
                            } else {
                                setErrors({submit: err.message});
                            }
                            setSubmitting(false);
                        }
                    }
                }}
            >

                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="grid gap-6">
                            <div className="grid gap-2">
                                <Label className={"text-textmedium"} htmlFor="email">Email</Label>
                                <Input className={"rounded border-border"} onChange={handleChange} value={values.email}
                                       id="email" type="email"
                                       placeholder="me@example.com"/>
                            </div>
                            <div className="grid gap-2">
                                <Label className={"text-textmedium"} htmlFor="name">Name</Label>
                                <Input className={"rounded border-border"} onChange={handleChange} value={values.name}
                                       id="name" type="text"
                                       placeholder="John Doe"/>
                            </div>
                            <div className="grid gap-2">
                                <Label className={"text-textmedium"}
                                       htmlFor="organization">Organization</Label>
                                <Input className={"rounded border-border"} disabled={!!inviteToken}
                                       onChange={handleChange} value={values.organization} id="organization" type="text"
                                       placeholder="Acme Inc"/>
                            </div>
                            <div className="grid gap-2">
                                <Label className={"text-textmedium"}
                                       htmlFor="password">Password</Label>
                                <Input className={"rounded border-border"} onChange={handleChange} id="password"
                                       type="password"
                                       value={values.password}
                                       placeholder={"supersecret123"}/>
                            </div>
                            <Button type="submit"
                                    className={"size-full text-base text-textlight border border-primary rounded bg-primarytransparent hover:bg-primaryhover"}
                                    disabled={isSubmitting}>
                                {isSubmitting && (
                                    <Icons.Spinner className="mr-2 h-4 w-4 animate-spin"/>
                                )}
                                Register with email
                            </Button>
                            {
                                ((touched.email && errors.email) || (touched.organization && errors.organization) || (touched.name && errors.name) || (touched.token && errors.token) || errors.submit || (touched.password && errors.password)) && (
                                    <Alert variant="destructive">
                                        <ExclamationTriangleIcon className="text-red-500 h-4 w-4"/>
                                        <AlertTitle className={"text-red-500"}>Error</AlertTitle>
                                        <AlertDescription className={"text-red-500"}>
                                            {errors.email}
                                            {errors.organization}
                                            {errors.name}
                                            {errors.token}
                                            {errors.password}
                                            {errors.submit}
                                        </AlertDescription>
                                    </Alert>)
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>);
}
