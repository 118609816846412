import React from "react";
import {usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import {Button} from "../ui/button";

interface HeaderProps {
    title: string;
    serviceName: string;
}

const Header: React.FC<HeaderProps> = (headerProps) => {
    const navigate = usePreserveQueryParamsNavigate();

    return <div className={"border-b h-[32px] w-full"}>
        <div className={"flex justify-between px-2"}>
            <div className={"text-textmedium text-xl truncate"}>{headerProps.title}</div>
        </div>
    </div>
}

export default Header;
