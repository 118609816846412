import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../ui/command";
import {cn} from "../../ui/lib/utils";
import React, {ReactElement} from "react";
import {XIcon} from "lucide-react";

interface DropDownItem {
    displayName?: string
    value: string
}

interface MultiSelectorDropDownProps {
    selectorSingular: string
    possibleItems: DropDownItem[]
    selectedItems: DropDownItem[]
    setSelectedItems: (value: DropDownItem[]) => void
    isSingleSelect?: boolean
}

// State is controlled outsdie of the component,
// This component just visualizes the selected items and allows the user to select more and remove them
// When those events happen, we fire the setSelectedItems function to update the state
function MultiSelectorDropDown(props: MultiSelectorDropDownProps) {
    const [open, setOpen] = React.useState(false)

    let selectedPills: ReactElement[] = [];
    props.selectedItems.forEach((selectedItem) => {
        selectedPills.push(<div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className={"flex border rounded"}>
                <div className={"flex p-1 bg-backgroundmedium justify-center items-center"}>
                    <div
                        className={"text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]"}>{selectedItem.displayName}</div>
                </div>
                <div className={"flex flex-col"}>
                    <XIcon onClick={() => {
                        let newFilter = props.selectedItems.filter((value) => value.value !== selectedItem.value);
                        props.setSelectedItems(newFilter);
                        setOpen(false);
                    }} className={"h-full hover:cursor-pointer hover:bg-primary rounded-tr rounded-br"}/>
                </div>
            </div>
        )
    })

    return <div className={"flex h-full flex-wrap border bg-backgroundmedium rounded"}>
        <div className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
            {props.selectorSingular}{!props.selectorSingular && "s"}
        </div>
        <Popover open={open} modal={true}>
            <PopoverTrigger asChild className={"flex grow"}>
                <div onClick={() =>
                    setOpen(true)
                } className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                    {
                        selectedPills.length === 0 &&
                        <div className={"text-textmedium flex flex-col justify-center grow"}>
                            {!props.isSingleSelect && "All " + props.selectorSingular + "s"}
                            {props.isSingleSelect && "Select " + props.selectorSingular}
                        </div>
                    }
                    {selectedPills}
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"} avoidCollisions={true}
                            onFocusOutside={() => setOpen(false)}
                            onEscapeKeyDown={() => setOpen(false)}
                            onInteractOutside={() => setOpen(false)}
                            className="p-0 text-textlight bg-backgroundmedium w-[40vw]"
            >
                <Command className={"z-50"}>
                    <CommandInput id={"free_text_input2"}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty>No {props.selectorSingular}s found.</CommandEmpty>
                        <CommandGroup>
                            {
                                props.possibleItems.map((item, index) => {
                                    return <CommandItem
                                        className={"ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                        key={index} onSelect={() => {
                                        if (props.isSingleSelect) {
                                            props.setSelectedItems([item]);
                                            setOpen(false);
                                            return;
                                        }
                                        setOpen(false);
                                        let newFilter = props.selectedItems;
                                        if (newFilter.includes(item)) {
                                            return;
                                        }
                                        newFilter.push(item);
                                        props.setSelectedItems([...newFilter]);
                                    }}>
                                        {item.displayName}
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}

function stringArrayToDropDownItemArray(array: string[]): DropDownItem[] {
    return array.map((value) => {
        return {displayName: value, value: value}
    }).filter((value) => value.value !== "")
}

function dropDownItemArrayToStringArray(array: DropDownItem[]): string[] {
    return array.map((value) => {
        return value.value
    })
}

export {
    MultiSelectorDropDown,
    stringArrayToDropDownItemArray,
    dropDownItemArrayToStringArray
}

export type {
    DropDownItem,
    MultiSelectorDropDownProps
}
