import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logoIcon from 'assets/images/Metoro_square_White_Text.png';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { toast } from 'components/ui/use-toast';
import {Toaster} from "../../components/ui/toaster";
import axios from 'utility/customAxios';

const PasswordReset: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract token from URL
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (!token) {
            toast({
                variant: "destructive",
                title: "Invalid Reset Link",
                description: "The password reset link is invalid or has expired.",
            });
            navigate('/login');
        }
    }, [token, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Passwords don't match",
                description: "Please ensure both passwords are identical.",
            });
            return;
        }

        if (password.length < 8) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Password too short",
                description: "Password must be at least 8 characters long.",
            });
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post('/api/v1/account/reset-password', {
                token: token,
                new_password: password,
            });

            if (response.status !== 200) {
                throw new Error('Failed to reset password');
            }

            toast({
                className: "text-textlight",
                title: "Password Reset Successful",
                description: "Your password has been successfully reset. Please log in with your new password.",
            });
            navigate('/login');
        } catch (error) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Error",
                description: "Failed to reset password. Please try again or request a new reset link.",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container relative h-screen flex items-center justify-center lg:max-w-none lg:px-0 lg:grid-cols-2 bg-background">
            <Toaster/>
            <div className="p-32 rounded border border-border flex-col justify-center items-center gap-16 inline-flex">
                <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                    <div className="flex flex-col space-y-2 text-center">
                        <div className="flex flex-none justify-center">
                            <img src={logoIcon} alt="Metoro" className="w-48 h-48 mx-auto"/>
                        </div>
                        <h1 className="text-2xl font-black tracking-tight text-textlight mb-4">
                            Reset Your Password
                        </h1>
                        <p className="text-sm text-textmedium">
                            Enter your new password below
                        </p>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <Input
                                id="password"
                                type="password"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-full"
                            />
                        </div>
                        <div className="space-y-2">
                            <Input
                                id="confirm-password"
                                type="password"
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="w-full"
                            />
                        </div>
                        <Button
                            type="submit"
                            className="w-full bg-primarytransparent border border-primary text-textmedium hover:text-textlight"
                            disabled={isLoading}
                        >
                            {isLoading ? "Resetting..." : "Reset Password"}
                        </Button>
                    </form>

                    <div className="flex flex-none justify-center">
                        <p className="text-sm text-textmedium">
                            Remember your password? <a href="/login" className="underline">Sign in</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
