// If last is defined, then it is a string representing the last x time from now
// If last is not defined, then start and end are Date objects
import {shortEnglishHumanizer} from "../components/ServicePanel/ServicePanel";

class TimeRange {
    last?: string;
    start?: Date;
    end?: Date;

    constructor(last?: string, start?: Date, end?: Date) {
        this.last = last
        this.start = start
        this.end = end
    }

    renderTimeRange(): string {
        if (this.last) {
            return `Past ${this.renderLast(this.last)}`
        }
        if (this.start && this.end) {
            return `${this.start.toLocaleString()} - ${this.end.toLocaleString()}`
        }
        return ""
    }

    renderDifference(): string {
        if (this.last) {
            return `${this.last}`
        }
        if (this.start && this.end) {
            // human duration
            const diff = this.end.getTime() - this.start.getTime()
            return shortEnglishHumanizer(diff, 0)
        }
        return ""
    }

    renderLast(last: string): string {
        // Get suffix
        const suffix = last[last.length - 1]
        // Get number
        const number = parseInt(last.slice(0, last.length - 1))

        let singular = ""

        if (suffix === "s") {
            singular = `${number} Second`
        }

        if (suffix === "m") {
            singular = `${number} Minute`
        }
        if (suffix === "h") {
            singular = `${number} Hour`
        }
        if (suffix === "d") {
            singular = `${number} Day`
        }
        if (suffix === "w") {
            singular = `${number} Week`
        }
        if (suffix === "M") {
            singular = `${number} Month`
        }
        if (suffix === "y") {
            singular = `${number} Year`
        }
        if (number === 1) {
            return singular
        }
        return `${singular}s`
    }

    getStartEnd(): [Date, Date] {
        if (this.last) {
            const now = new Date()
            const suffix = this.last[this.last.length - 1]
            const number = parseInt(this.last.slice(0, this.last.length - 1))
            let start = new Date()
            let end = new Date()
            if (suffix === "s") {
                start.setSeconds(now.getSeconds() - number)
            }
            if (suffix === "m") {
                start.setMinutes(now.getMinutes() - number)
            }
            if (suffix === "h") {
                start.setHours(now.getHours() - number)
            }
            if (suffix === "d") {
                start.setDate(now.getDate() - number)
            }
            if (suffix === "w") {
                start.setDate(now.getDate() - number * 7)
            }
            if (suffix === "M") {
                start.setMonth(now.getMonth() - number)
            }
            if (suffix === "y") {
                start.setFullYear(now.getFullYear() - number)
            }
            return [start, end]
        }
        if (this.start && this.end) {
            return [this.start, this.end]
        }
        return [new Date(), new Date()]
    }

}

export {TimeRange};