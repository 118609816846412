// A filter by
// So if I have "service_a" as a filter then the alert will only apply to service_a
interface LogFilter {
    services: string[],
    clusters: string[],
}

interface LogEventRegexMatchPayload {
    regex: string,
}

interface LogEvent {
    id: string,
    name: string,
    description: string,
}

const LogEventRegexMatch = {
    id: "regex_match",
    name: "Regex Match",
    description: "Alert when a log is emitted which matches the regex in re2 syntax.",
}

const logEvents = [LogEventRegexMatch]

interface LogAlert {
    filters: LogFilter
    monitorEvent: LogEvent
    eventPayload: LogEventRegexMatchPayload
}

const defaultLogAlert = {
    filters: {
        services: [],
        clusters: [],
    },
    monitorEvent: LogEventRegexMatch,
    eventPayload: {
        regex: "",
    }
}

export {
    logEvents,
    LogEventRegexMatch,
    defaultLogAlert
}
export type {
    LogFilter,
    LogEvent,
    LogAlert,
    LogEventRegexMatchPayload
}

