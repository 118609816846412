import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import {GuardProps} from 'types/auth';
import ReactGA from "react-ga4";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";

ReactGA.initialize('G-HP6R9285D7');

const AuthGuard = ({children}: GuardProps) => {
    const {isLoggedIn} = useAuth();
    const navigate = usePreserveQueryParamsNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', {
                state: {
                    from: location.pathname
                },
                replace: true
            });
        }
    }, [isLoggedIn, navigate, location]);

    return children;
};

export default AuthGuard;