import React from 'react'
import logoIcon from 'assets/images/Metoro_square_White_Text.png';
import {UserAuthForm} from "components/login/UserAuthForm";

interface LoginProps extends React.HTMLAttributes<HTMLDivElement> {
    isRegister: boolean;
}

const LoginPage: React.FC<LoginProps> = (props) => {
    const [isRegister, setIsRegister] = React.useState(props.isRegister);

    return (
        <div
            className="container relative h-screen flex items-center justify-center lg:max-w-none lg:px-0 lg:grid-cols-2 bg-background">
            <div
                className="p-32 rounded border border-border flex-col justify-center items-center gap-16 inline-flex">
                <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                    <div className="flex flex-col space-y-2 text-center ">
                        <div className={"flex flex-none justify-center"}>
                            <img src={logoIcon} alt="Metoro" className="w-48 h-48 mx-auto"/>
                        </div>
                        <div className={"flex flex-none justify-center"}>
                            {
                                props.isRegister ?
                                    <div className="text-2xl font-black tracking-tight text-textlight mb-4">Create an
                                        account</div>
                                    : <div className="text-5xl font-black tracking-tight text-textlight"></div>
                            }
                        </div>

                        <div className={"flex flex-none justify-center"}>
                            {!props.isRegister ? (
                                <p className="text-sm text-textmedium">
                                    New to Metoro? <a href="/register" className={"underline"} onClick={() => {
                                    setIsRegister(true)
                                    return false
                                }}>Sign up</a>
                                </p>) : (
                                <p className="text-sm text-textmedium">
                                    Already have an account? <a href="/login" className={"underline"}
                                                                onClick={() => {
                                                                    setIsRegister(false)
                                                                    return false
                                                                }}>Sign in</a>
                                </p>)
                            }
                        </div>
                    </div>
                    <div className={"flex flex-none justify-center"}>
                        <UserAuthForm className={"w-full"} isRegister={props.isRegister}/>
                    </div>
                    <div className={"flex flex-none justify-center"}>
                        {props.isRegister && (
                            <p className="px-8 text-center text-sm text-textdark">
                                By clicking continue, you agree to our{" "}
                                <a
                                    href="https://metoro.io/static/terms/terms.pdf"
                                    className="underline underline-offset-4 hover:text-primary text-textdark"
                                >
                                    Terms of Service
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://metoro.io/static/privacy/privacy.pdf"
                                    className="underline underline-offset-4 hover:text-primary"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
