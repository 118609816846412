import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../ui/dropdown-menu";
import {ChevronDown} from "lucide-react";
import React from "react";
import {DropDownItem} from "./MultiSelectorDropdown/MultiSelectorDropDown";


interface SingleSelectDropdownProps {
    selectedItemTitle: string
    selectedItem: DropDownItem
    possibleItems: DropDownItem[]
    setSelectedItem: (value: DropDownItem) => void
}

export function SingleSelectDropdown(props: SingleSelectDropdownProps) {
    return <DropdownMenu>
        <DropdownMenuTrigger asChild className={"h-full"}>
            <div
                className={"flex rounded border border-border bg-backgroundmedium justify-center items-center hover:cursor-pointer hover:border-primary"}>
                <div
                    className={"text-textlight px-2 h-full flex flex-col justify-center text-center text border border-secondary bg-secondarytransparenter"}>
                    <div className={"flex flex-col items-center text-center"}>
                        {props.selectedItemTitle}
                    </div>
                </div>
                <div className={"border-l flex bg-backgroundlight justify-center items-center px-2 h-full"}>
                    <div
                        className={"text-textmedium bg-backgroundlight pr-2"}>
                        {props.selectedItem.displayName}
                    </div>
                    <ChevronDown
                        className={"bg-backgroundlight text-textmedium h-4 w-4"}/>
                </div>
            </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 bg-backgroundmedium rounded">
            <DropdownMenuLabel className={"text-textmedium"}>{props.selectedItemTitle}</DropdownMenuLabel>
            <DropdownMenuSeparator className={"bg-border"}/>
            <DropdownMenuGroup>
                {props.possibleItems.map((item) => (
                    <DropdownMenuItem key={item.value}
                                      className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"
                                          + (props.selectedItem === item ? " bg-backgroundlight" : "")}
                                      onClick={() => props.setSelectedItem(item)}>
                        {item.displayName}
                    </DropdownMenuItem>
                ))}
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
}