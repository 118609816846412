import React from 'react'
import {Provider} from 'react-redux'
import RootComponent from './RootComponent'
import {store} from './store/reducers/store'
import {JWTProvider as AuthProvider} from 'contexts/JWTContext';


const App: React.FC = () => {
    return (
        <Provider store={store}>
            <AuthProvider>
                <RootComponent/>
            </AuthProvider>
        </Provider>
    )
}

export default App
