import {Outlet} from 'react-router-dom';
import React from "react";

import Onboarding from "../pages/Onboarding";
import PostHogProvider from "../utility/PostHog";


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const [isCollapsed, setIsCollapsed] = React.useState(true)

    return (
        <PostHogProvider>
            <Onboarding>
                <Outlet/>
            </Onboarding>
        </PostHogProvider>
        // This is needed to allow tooltips inside the application, I'm not sure why...
        // <TooltipProvider delayDuration={0}>
        //     <div className={"w-full min min-h-screen flex"}>
        //         <ResizablePanelGroup
        //             direction="horizontal"
        //             onLayout={(sizes: number[]) => {
        //                 document.cookie = `react-resizable-panels:layout=${JSON.stringify(
        //                     sizes
        //                 )}`
        //             }}
        //             className="h-full items-stretch text-black">
        //             <ResizablePanel
        //                 defaultSize={4}
        //                 collapsedSize={4}
        //                 collapsible={true}
        //                 minSize={9}
        //                 maxSize={20}
        //                 onResize={(sizes) => {
        //                     document.cookie = `react-resizable-panels:layout=${JSON.stringify(
        //                         sizes
        //                     )}`
        //                 }}
        //                 onExpand={() => {
        //                     setIsCollapsed(false)
        //                 }}
        //                 onCollapse={() => {
        //                     setIsCollapsed(true)
        //                 }}
        //                 className={cn(isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out")}
        //             >
        //             </ResizablePanel>
        //             <ResizableHandle className={"h-screen"} withHandle/>
        //             <ResizablePanel className={"w-full"}>
        //                 <div className={"max-h-screen overflow-y-auto"}>
        //
        //                 </div>
        //             </ResizablePanel>
        //         </ResizablePanelGroup>
        //     </div>
        //     <Toaster />
        // </TooltipProvider>
    );
};

export default MainLayout;
