import {LOGIN, LOGOUT, REGISTER} from './actions';
import {AuthActionProps, AuthProps} from 'types/auth';

export const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};


const auth = (state = initialState, action: AuthActionProps) => {
    switch (action.type) {
        case REGISTER: {
            const {user} = action.payload!;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const {user} = action.payload!;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return {...state};
        }
    }
};

export default auth;
