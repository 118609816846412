import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';

const LoaderWrapper = styled('div')(({theme}) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2)
    }
}));

export interface LoaderProps extends LinearProgressProps {
}

const Loader = () => (
    <LoaderWrapper>
        <LinearProgress color="primary"/>
    </LoaderWrapper>
);

const CommonLayout = ({layout = 'blank'}: { layout?: string }) => {

    return (
        <>
            {(layout === 'landing' || layout === 'simple') && (
                <Suspense fallback={<Loader/>}>
                    {/* TODO: This is where Header component will be*/}
                    <Outlet/>
                    {/* TODO: This is where Footer component will be*/}
                </Suspense>
            )}
            {layout === 'blank' && <Outlet/>}
        </>
    );
};

export default CommonLayout;
