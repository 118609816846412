import React from "react";
import yaml from "js-yaml";
import SyntaxHighlighter from "react-syntax-highlighter";


export function PrettyYamlViewer(props: { yamlString: string }) {
    let formattedYaml;
    try {
        const yamlObject = yaml.load(props.yamlString);
        formattedYaml = yaml.dump(yamlObject);
    } catch (e) {
        return <div>Error parsing YAML</div>;
    }

    return (
        <div className={"border flex min-h-0 min-w-0 grow shrink"}>
        {/*// @ts-ignore*/}
        <SyntaxHighlighter
            customStyle={{
                width: "100%",
            }}
            language="yaml"
            // @ts-ignore
            style={customTheme}>
            {formattedYaml}
        </SyntaxHighlighter>
        </div>
    );
}

export default PrettyYamlViewer;

export const customTheme =  {
    "code[class*=\"language-\"]": {
        "color": "#C6D3E2",
        "textShadow": "0 1px rgba(0, 0, 0, 0.3)",
        "fontFamily": "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
        "direction": "ltr",
        "textAlign": "left",
        "whiteSpace": "pre",
        "wordSpacing": "normal",
        "wordBreak": "normal",
        "lineHeight": "1.5",
        "MozTabSize": "4",
        "OTabSize": "4",
        "tabSize": "4",
        "WebkitHyphens": "none",
        "MozHyphens": "none",
        "msHyphens": "none",
        "hyphens": "none"
    },
    "pre[class*=\"language-\"]": {
        "color": "#C6D3E2",
        "textShadow": "0 1px rgba(0, 0, 0, 0.3)",
        "fontFamily": "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
        "direction": "ltr",
        "textAlign": "left",
        "whiteSpace": "pre",
        "wordSpacing": "normal",
        "wordBreak": "normal",
        "lineHeight": "1.5",
        "MozTabSize": "4",
        "OTabSize": "4",
        "tabSize": "4",
        "WebkitHyphens": "none",
        "MozHyphens": "none",
        "msHyphens": "none",
        "hyphens": "none",
        "padding": "1em",
        "margin": ".5em 0",
        "overflow": "auto",
        "borderRadius": "0.3em",
        "background": "#151F32"
    },
    ":not(pre) > code[class*=\"language-\"]": {
        "background": "#151F32",
        "padding": ".1em",
        "borderRadius": ".3em"
    },
    "comment": {
        "color": "#EBF1F7"
    },
    "prolog": {
        "color": "#EBF1F7"
    },
    "doctype": {
        "color": "#EBF1F7"
    },
    "cdata": {
        "color": "#EBF1F7"
    },
    "punctuation": {
        "color": "#EBF1F7"
    },
    ".namespace": {
        "Opacity": ".7"
    },
    "property": {
        "color": "#96CBFE"
    },
    "keyword": {
        "color": "#96CBFE"
    },
    "tag": {
        "color": "#96CBFE"
    },
    "class-name": {
        "color": "#FFFFB6",
        "textDecoration": "underline"
    },
    "boolean": {
        "color": "#99CC99"
    },
    "constant": {
        "color": "#99CC99"
    },
    "symbol": {
        "color": "#f92672"
    },
    "deleted": {
        "color": "#f92672"
    },
    "number": {
        "color": "#FF73FD"
    },
    "selector": {
        "color": "#A8FF60"
    },
    "attr-name": {
        "color": "#A8FF60"
    },
    "string": {
        "color": "#A8FF60"
    },
    "char": {
        "color": "#A8FF60"
    },
    "builtin": {
        "color": "#A8FF60"
    },
    "inserted": {
        "color": "#A8FF60"
    },
    "variable": {
        "color": "#C6C5FE"
    },
    "operator": {
        "color": "#EDEDED"
    },
    "entity": {
        "color": "#FFFFB6",
        "cursor": "help"
    },
    "url": {
        "color": "#96CBFE"
    },
    ".language-css .token.string": {
        "color": "#87C38A"
    },
    ".style .token.string": {
        "color": "#87C38A"
    },
    "atrule": {
        "color": "#F9EE98"
    },
    "attr-value": {
        "color": "#F9EE98"
    },
    "function": {
        "color": "#DAD085"
    },
    "regex": {
        "color": "#E9C062"
    },
    "important": {
        "color": "#fd971f",
        "fontWeight": "bold"
    },
    "bold": {
        "fontWeight": "bold"
    },
    "italic": {
        "fontStyle": "italic"
    }
}