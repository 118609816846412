import {GridStack} from "gridstack";
import {RuntimeVariable} from "../Dashboard";

interface WidgetPosition {
    x?: number;
    w?: number;
}

const getVariableResolvedValuesAtGroupInternal = (widgetId: string,
                                                  grids: Map<string, GridStack>,
                                                  runtimeVariables: Map<string, RuntimeVariable[]>,
                                                  // This accumulator is used to store the runtime variables for each grid
                                                  // The first value is current grid and the last value is the root grid
                                                  accumulator: RuntimeVariable[][],
) => {
    accumulator.push(runtimeVariables.get(widgetId) ?? [])

    const {
        element, grid, gridWidgetId
    } = findParentGrid(widgetId, grids)
    console.log(gridWidgetId)

    if (gridWidgetId) {
        getVariableResolvedValuesAtGroupInternal(gridWidgetId, grids, runtimeVariables, accumulator)
    }
}

export const getVariableResolvedValuesAtGroup = (widgetId: string,
                                                 grids: Map<string, GridStack>,
                                                 runtimeVariables: Map<string, RuntimeVariable[]>
) => {
    const accumulator: RuntimeVariable[][] = []
    getVariableResolvedValuesAtGroupInternal(widgetId, grids, runtimeVariables, accumulator)
    let resolvedVariables: Map<string, RuntimeVariable> = new Map<string, RuntimeVariable>()
    // Iterate in reverse order so that child variables overwrite parent variable
    for (let i = accumulator.length - 1; i >= 0; i--) {
        accumulator[i].forEach(variable => {
            resolvedVariables.set(variable.name, variable)
        })
    }
    // Make array of resolved variables and return it as a data
    return Array.from(resolvedVariables.values())
}

/**
 * Finds the parent GridStack instance for a given widget
 * @param widgetId - ID of the widget element
 * @param grids - Map of all grid instances
 * @returns Object containing the widget element and its parent grid (if any)
 */
export const findParentGrid = (
    widgetId: string,
    grids: Map<string, GridStack>
): { element: HTMLElement | null; grid: GridStack | undefined, gridWidgetId?: string } => {
    console.log(widgetId)
    let widgetEl = document.getElementById(widgetId)

    const parentGridEl = widgetEl?.closest('[class*="grid-stack-static"]') ?? widgetEl?.closest('[class*="grid-stack-animate"]')  ?? null

    if (!parentGridEl) {
        return {element: widgetEl, grid: undefined}
    }

    const gridClassId = parentGridEl.className.split(' ').find(c => c.startsWith('grid-id'))
    const parentGridWidgetId = gridClassId?.replace('grid-', '')

    const parentGrid = gridClassId ? grids.get(gridClassId) : undefined

    return {element: widgetEl, grid: parentGrid, gridWidgetId: parentGridWidgetId}
};

/**
 * Determines if a widget takes up the full width of its parent grid
 * @param widgetEl - The widget's DOM element
 * @param parentGrid - The parent GridStack instance
 * @param widgetPosition - The widget's position in the grid
 * @returns boolean indicating if the widget is full width
 */
export const determineFullWidth = (
    widgetEl: HTMLElement | null,
    parentGrid: GridStack | undefined,
    widgetPosition: WidgetPosition
): boolean => {
    // For the root grid (no parent), check if widget extends to full width (x + w = 12)
    if (parentGrid === undefined) {
        return widgetPosition.x !== undefined &&
            widgetPosition.w !== undefined &&
            widgetPosition.x + widgetPosition.w === 12;
    }

    // For nested grids, compare actual pixel widths
    const parentWidth = parentGrid.el?.getBoundingClientRect().width ?? 0;
    const widgetWidth = widgetEl?.getBoundingClientRect().width ?? 0;

    // Consider full width if difference is less than 10px
    return Math.abs(parentWidth - widgetWidth) < 10;
};
