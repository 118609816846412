import React, {ReactElement} from "react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../ui/tooltip";
import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {Command, CommandGroup, CommandItem, CommandList} from "../../ui/command";
import {cn} from "../../ui/lib/utils";

export function TagTitle(props: {
    title: string,
    icon: ReactElement
}) {
    return <div className="w-full h-6 rounded justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-6">
            {props.icon}
        </div>
        <div className="text-textdark text-sm font-medium leading-[14px]">{props.title}</div>
    </div>
}

export function Tag(props: {
    tagKey: string,
    tagValue: string,
    borderColour?: string,
    tooltipContent?: string
    filter?: Map<string, string[]>,
    setFilter?: (filter: Map<string, string[]>) => void,
    excludeFilter?: Map<string, string[]>,
    setExcludeFilter?: (filter: Map<string, string[]>) => void
}) {
    const [isOpen, setIsOpen] = React.useState(false)

    let borderColour = ""
    if (typeof props.borderColour !== 'undefined') {
        borderColour = props.borderColour
    }
    return <Popover open={isOpen}>
        <PopoverTrigger onClick={() => {
            if (props.filter || props.excludeFilter) {
                setIsOpen(prev => !prev)
            }
        }}>
            <div className={cn("group justify-start items-start flex ", props.filter ? "hover:cursor-pointer" : "")}>
                <div
                    className={cn(`p-2 bg-backgrounddark rounded-tl rounded-bl border ${borderColour}  justify-start items-start flex`, props.filter ? "group-hover:border-primary" : "")}>
                    <div className="text-center text-textmedium text-sm font-medium leading-[16px]">
                        {props.tagKey}
                    </div>
                </div>
                <div
                    className={cn(`self-stretch p-2 bg-backgroundlight rounded-tr rounded-br ${borderColour} border-r border-t border-b justify-center items-center flex`, props.filter ? " group-hover:border-primary" : "")}>
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger
                                className="max-w-xs text-center text-textlight text-sm font-medium leading-[16px] truncate">{props.tagValue}</TooltipTrigger>
                            <TooltipContent
                                className={"bg-background border text-textlight rounded hover:cursor-text"}>{props.tooltipContent ? props.tooltipContent : props.tagValue}</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>

        </PopoverTrigger>
        <PopoverContent
            onBlur={() => setIsOpen(false)}
            onKeyDown={
                (e) => {
                    if (e.key === "Escape") {
                        setIsOpen(false)
                    }
                }
            }
            className={"bg-backgroundmedium border rounded text-textmedium"}>
            <Command>
                <CommandList>
                    <CommandGroup>
                        <CommandItem
                            className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                            key={props.tagKey + "-filter"}
                            onSelect={() => {
                                if (props.filter) {
                                    const newFilter = new Map(props.filter);
                                    if (!newFilter.has(props.tagKey)) {
                                        newFilter.set(props.tagKey, [props.tagValue]);
                                    } else {
                                        newFilter.get(props.tagKey)?.push(props.tagValue)
                                    }
                                    props.setFilter!(newFilter);
                                    setIsOpen(false);
                                }
                            }}
                        >
                            Filter by this tag
                        </CommandItem>
                        <CommandItem
                            className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                            key={props.tagKey + "-exclude"}
                            onSelect={() => {
                                if (props.excludeFilter) {
                                    const newFilter = new Map(props.excludeFilter);
                                    if (!newFilter.has(props.tagKey)) {
                                        newFilter.set(props.tagKey, [props.tagValue]);
                                    } else {
                                        newFilter.get(props.tagKey)?.push(props.tagValue)
                                    }
                                    props.setExcludeFilter!(newFilter);
                                    setIsOpen(false);
                                }
                            }}
                        >
                            Exclude this tag
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
}

function fillMissingTag(input: string) {
    if (input === "") {
        return "unknown";
    }
    return input
}

function TagContainer(props: {
    title: string
    attributes: Object,
    icon: ReactElement,
    setWidth?: string
    filter?: Map<string, string[]>,
    setFilter?: (filter: Map<string, string[]>) => void,
    excludeFilter?: Map<string, string[]>,
    setExcludeFilter?: (filter: Map<string, string[]>) => void
}) {
    if (props.attributes === null || props.attributes === undefined) {
        return <></>
    }
    const tags: ReactElement[] = [];
    const attrMap = new Map(Object.entries(props.attributes));
    attrMap.forEach((value: string, key: string) => {
        if (key !== "" && value !== "") {
            tags.push(<Tag
                tagKey={key} tagValue={fillMissingTag(value)}
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}

            />)
        }
    });
    const width = props.setWidth ? props.setWidth : "w-[504px]";
    return <div
        className={`${width} p-4 bg-backgroundmedium rounded border flex-col justify-start items-start gap-2.5 inline-flex w-full`}>
        <TagTitle title={props.title} icon={props.icon}/>
        <div className="rounded-sm justify-start items-center gap-2 inline-flex flex-wrap">
            {tags}
        </div>
    </div>
}

export default TagContainer;




