export function getLogLeveLIndicatorColor(levelName: string) {
    let indicatorColor = "bg-gray-500";
    if (levelName === "info") {
        indicatorColor = "bg-blue-500";
    }
    if (levelName === "error") {
        indicatorColor = "bg-red-500";
    }
    if (levelName === "warning") {
        indicatorColor = "bg-orange-400";
    }
    if (levelName === "unknown") {
        indicatorColor = "bg-gray-500";
    }
    return indicatorColor;
}
