import React, {PropsWithChildren} from "react";
import {PostHogProvider as ThirdPartyPostHogProvider} from "posthog-js/react";

const PUBLIC_POSTHOG_KEY = 'phc_CcaPzJ2qLoNEKELuaN8IVIVJdwMd5tfJBEjjgQyZ06z';

interface PostHogProviderProps {
}

const PostHogProvider: React.FC<PropsWithChildren<PostHogProviderProps>> = ({children}) => {
    return (
        <ThirdPartyPostHogProvider
            apiKey={PUBLIC_POSTHOG_KEY}
            options={{
                api_host: 'https://e.' + window.location.hostname,
                ui_host: 'https://app.posthog.com',
            }}
        >
            {children}
        </ThirdPartyPostHogProvider>
    );
}

export default PostHogProvider;