import React from "react";
import {Dialog, DialogContent, DialogTrigger} from "../ui/dialog";
import {K8sPodDetails} from "../K8sInfo/model";
import {Table, TableHead, TableHeader, TableRow, TableCell, TableBody, TableFooter} from "../ui/table";
import {ShieldAlert} from "lucide-react";
import {Link} from "react-router-dom";
import {Button} from "../ui/button";


interface WarningBannerProps {
    type: WarningBannerType
    message: string;
    details?: string;
    pods?: K8sPodDetails[];
}

export enum WarningBannerType {
    trialEndBanner = "trialEndBanner",
    nodeAgentHealth = "nodeAgentHealth"
}

export function WarningBanner(props: WarningBannerProps) {
    if (props.type === WarningBannerType.trialEndBanner) {
        return <div className={"text-sm bg-purple-400/10 border border-purple-400 px-2 py-1"}>{props.message}</div>
    }

    if (props.type === WarningBannerType.nodeAgentHealth) {
        return (
            <Dialog>
                <DialogTrigger>
                    <div
                        className={"flex items-center hover:bg-red-400/20 text-sm bg-red-400/10 border border-red-400 px-2 py-1 hover:text-textlight"}>
                        <ShieldAlert className={"w-5 h-5 text-red-400"}/>
                        <div>{props.message}</div>
                    </div>
                </DialogTrigger>
                <DialogContent className={"w-2/3 text-textmedium h-4/5 overflow-y-auto"}>
                    <div className={"w-full flex items-center gap-2 font-semibold"}>
                        <ShieldAlert className={"text-red-400 w-8 h-8"}/>
                        <div
                            className={"font-semibold text-base"}>{props.pods ? "Some node agents aren't running. This could lead to inconsistent telemetry. Please address the following issues:" : ""}</div>
                    </div>
                    {props.pods && <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className={"w-[250px] font-semibold"}>Environment</TableHead>
                                <TableHead className={"w-[330px] font-semibold"}>Node</TableHead>
                                <TableHead className="w-[250px] font-semibold">Pod Name</TableHead>
                                <TableHead className={"font-semibold"}>Status</TableHead>
                                <TableHead className={"text-right font-semibold"}>Kubernetes Scheduling
                                    Information</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {props.pods.map((pod) => (
                                <TableRow key={pod.uuid}>
                                    <TableCell>{pod.environment}</TableCell>
                                    <TableHead className={"w-[330px] font-semibold"}>
                                        {pod.nodeName != "" ? pod.nodeName : "n/a"}
                                    </TableHead>
                                    <TableCell className="font-medium">
                                        <Link className={"p-2 bg-primarytransparent border border-primary text-textlight"}
                                                to={`/service?service=/k8s/metoro/metoro-node-agent&tab=k8s`}
                                        >{pod.name}</Link>
                                    </TableCell>
                                    <TableCell>{pod.status}</TableCell>
                                    <TableCell className="text-right">{pod.message}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                    <div className={"text-textdark font-semibold"}>Please contact Metoro team if you need assistance by
                        joining
                        <a href={"https://metoro.io/docs/community-slack"} className={"text-primary"}> Metoro community
                            slack </a>
                        or by emailing us at <a href={"mailto:support@metoro.io"}
                                                className={"text-primary"}>support@metoro.io</a>.
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return <></>

}

