import {ApiServerAlert} from "../AlertCreation";

const timeAggregations = ["p50", "p90", "p95", "p99"]
const nanoToMilliSeconds = 1_000_000
function checkIsTime(alert: ApiServerAlert) {
    if (alert && alert?.type == "Trace" && alert?.traceAlert) {
        const aggregation = alert?.traceAlert.filters.aggregation
        return timeAggregations.includes(aggregation?.toLowerCase())
    }
    return false
}

function adjustThreshold(alert: ApiServerAlert) {
    if (checkIsTime(alert) && alert?.traceAlert) {
        return String(Number(alert?.traceAlert.alarmCondition.threshold) / nanoToMilliSeconds)
    } else {
        if (alert && alert.traceAlert) {
            return alert.traceAlert.alarmCondition.threshold
        }
    }
    return "0"
}

function adjustAlertThreshold(alert: ApiServerAlert): ApiServerAlert {
    if (checkIsTime(alert) && alert?.traceAlert) {
        const thresholdToSet =  Number(alert?.traceAlert.alarmCondition.threshold) / nanoToMilliSeconds
        alert.traceAlert.alarmCondition.threshold = String(thresholdToSet)
    }
    return alert
}


export {checkIsTime, adjustThreshold, adjustAlertThreshold}