import {useEffect} from "react";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";


function SlackRedirect() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();

    useEffect(() => {
        let code = searchParams.get("code");
        let state = searchParams.get("state");

        if (code && state) {
            // Make a request to the backend to drop off the slack code
            // and state to get the slack token
            axios.post("/api/v1/slack/token", {
                code: code,
                state: state
            }).then((response) => {
                // Redirect to the dashboard
                navigate("/settings")
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [searchParams]);
    return <div className={"text-textmedium"}>Redirecting...</div>
}

export default SlackRedirect;