// A group by
// So if I have service as a scope then there will actually be an independent monitor for each service
interface KubernetesScope {
    service: boolean,
    environment: boolean,
}

// A filter by
// So if I have "service_a" as a filter then the alert will only apply to service_a
interface KubernetesFilter {
    services: string[],
    clusters: string[],
}

interface KubernetesEvent {
    id: string,
    name: string,
    description: string,
}

const KubernetesEventPodRestart = {
    id: "pod_restart",
    name: "Pod Restart",
    description: "Alert when a pod is restarted",
}
const KubernetesEventAvailabilityDrop = {
    id: "availability_drop",
    name: "Availability Drop",
    description: "Alert when the a service is not available as determined by the service's health check",
}

const kubernetesEvents = [KubernetesEventPodRestart
    // To add back in whene we implement it
//    , KubernetesEventAvailabilityDrop
]

interface KubernetesAlert {
    filters: KubernetesFilter
    scope: KubernetesScope
    monitorEvent: KubernetesEvent
}

const defaultKubernetesAlert = {
    filters: {
        services: [],
        clusters: [],
    },
    scope: {
        service: false,
        environment: false,
    },
    monitorEvent: KubernetesEventPodRestart,
}

export {
    kubernetesEvents,
    KubernetesEventPodRestart,
    KubernetesEventAvailabilityDrop,
    defaultKubernetesAlert
}
export type {
    KubernetesScope,
    KubernetesFilter,
    KubernetesEvent,
    KubernetesAlert
}

