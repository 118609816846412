import {useEffect, useState} from "react";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";
import {MultiSelectorDropDown, stringArrayToDropDownItemArray} from "./Input/MultiSelectorDropdown/MultiSelectorDropDown";

export function NamespaceSelector() {
    const [searchParams, setSearchParams] = useSearchParams();
    let params = new URLSearchParams(searchParams);
    const [namespaces, setNamespaces] = useState<string[]>([])
    const [selectedNamespace, setSelectedNamespace] = useState<string>(params.get("namespace") || "")
    const [environment, setEnvironment] = useState<string>(params.get("environment") || "")

    useEffect(() => {
        setEnvironment(params.get("environment") || "")
    }, [searchParams]);

    useEffect(() => {
        // Fetch namespaces
        axios.get("/api/v1/namespaces?environment=" + environment).then((response) => {
            let namespacesToUse = response.data.namespaces;
            // Sort alphabetically a-z
            namespacesToUse.sort();
            setNamespaces(namespacesToUse)
        }).catch((e) => {
            console.error(e)
        })
    }, [environment])

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("namespace", selectedNamespace);
            return prev;
        });
    }, [selectedNamespace])

    const possibleItems = stringArrayToDropDownItemArray(namespaces);
    const selectedItems = selectedNamespace ? stringArrayToDropDownItemArray([selectedNamespace]) : [];

    return (
            <MultiSelectorDropDown
                selectorSingular="Namespace"
                possibleItems={possibleItems}
                selectedItems={selectedItems}
                setSelectedItems={(items) => {
                    setSelectedNamespace(items.length > 0 ? items[0].value : "");
                }}
                isSingleSelect={true}
            />
    )
}
