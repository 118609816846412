import React, {ReactElement, useEffect, useRef, useState} from "react";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip
} from "chart.js";
import 'chartjs-adapter-date-fns';
import {Bar, Line} from "react-chartjs-2";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import axios from "../utility/customAxios";
import {plainToInstance} from "class-transformer";
import {shortEnglishHumanizer} from "../components/ServicePanel/ServicePanel";
import {AxiosPromise} from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Dashboard, dashboardJsonReplacer, RuntimeVariable} from "../components/Dashboarding/Dashboard";
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import {AlertDestinationType, AlertType, ApiServerAlert, ThresholdComparator} from "./AlertCreation";
import {TimeRange} from "../types/time";
import {useDispatch, useSelector} from "react-redux";
import timerange, {set} from "../store/reducers/timerange";
import {useDebouncedCallback} from "use-debounce";
import {MetricFunction} from "../components/Dashboarding/widgets/MetricSelector";
import {
    AggregationFunction,
    EvalType,
    MissingDatapointBehavior,
    MonitorEvaluationPayload,
    WindowUnit
} from "./alerts/MetricAlert";
import {EllipsisIcon, InfoIcon} from "lucide-react";
import {formatFilterValues} from "../components/Filter/Filter";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../components/ui/dropdown-menu";
import {MetricChartWidget} from "../components/Dashboarding/internalwidgets";
import {Dialog, DialogContent, DialogTitle} from "../components/ui/dialog";
import {DashboardSearchResponse} from "./DashboardSearch";
import {DropDownItem, MultiSelectorDropDown} from "../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {Button} from "../components/ui/button";
import {useSearchParams} from "react-router-dom";
import isEqual from 'lodash/isEqual';
import {DateTimePrecision, getPreferredFormatString} from "../utility/displayDateTime";

interface GetMetricRequest {
    metricName: string;
    startTime: number;
    endTime: number;
    filters: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits: string[];
    aggregation: string;
    isRate: boolean;
    functions: MetricFunction[];
    limitResults: boolean;
    bucketSize?: number;
}

export interface MathExpression {
    variables: string[];
    expression: string;
}

// A variable in a math expression
interface MathExpressionVariable {
    // variable name, e.g. a, b, c, etc.
    name: string;
    // value is the name of the metric this variable represents
    // e.g. a / 60 where a = requests_total metric.
    value: string;
}

interface GetMetricAggregateEvaluationRequest extends GetMetricRequest {
    aggregateParams: MonitorEvaluationPayload;
}

export class GetMetricResponse {
    constructor(metric: Metric, isResultLimited: boolean, resultLen: number) {
        this.metric = metric;
        this.isResultLimited = isResultLimited;
        this.resultLen = resultLen;
    }

    metric: Metric;
    isResultLimited: boolean;
    resultLen: number;
}

interface Metric {
    // The name of the metric
    name: string;
    // The time series that make up this metric
    timeSeries: TimeSeries[];
    // The type of the metric, e.g. "gauge", "counter", etc.
    type: string;
    // The attributes that are common to all time series
    attributes: Map<string, string[]>;
}

export interface TimeSeries {
    data: DataPoint[];
    // The attributes that are specific to this time series
    attributes: Map<string, string>;
}

interface DataPoint {
    // The time at which the data point was recorded in milliseconds since epoch
    time: number;
    // The value of the data point
    value: number;
}

interface ColorPairing {
    backgroundColor: string;
    borderColor: string;
}

const colorings: ColorPairing[] = [
    {
        borderColor: "rgb(55, 147, 255)",
        backgroundColor: "rgba(55, 147, 255, 0.2)",
    },
    {
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
    },
    {
        borderColor: "rgb(255, 206, 86)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
    },
    {
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
    },
    {
        borderColor: "rgb(255, 159, 64)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
    },
    {
        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
    },
    {
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.2)",
    },

    {
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
    },
]

function MetricToChartData(metric: Metric | undefined, isTime: boolean, colorBackground: string | undefined = undefined, colorBorder: string | undefined = undefined, customColours: Map<Record<string, string>, string[]> | undefined = undefined): any {
    if (metric === undefined) {
        return undefined;
    }
    let data = [];
    let i = 0;
    // Sorted by total value of the time series
    let sorted = metric.timeSeries.sort((a, b) => {
        let totalA = a.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        let totalB = b.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        return totalB - totalA
    })

    for (let timeSeries of sorted) {
        let label = "";
        const entries = Object.entries(timeSeries.attributes)
        let customColourBackground = undefined;
        let customColourBorder = undefined;
        if (customColours !== undefined) {
            for (let [attributePair, colour] of customColours) {
                for (let entry of entries) {
                    if (entry[0] === attributePair.key && entry[1] === attributePair.value) {
                        customColourBackground = colour[1];
                        customColourBorder = colour[0];
                        break;
                    }
                }
            }
        }
        for (const entry of entries) {
            label += entry[0] + "=" + formatFilterValues(entry[0], entry[1] as string) + ", ";
        }
        if (label.length > 0) {
            label = label.substring(0, label.length - 2);
        }
        let dataset = {
            label: label,
            data: timeSeries.data.map((dataPoint) => {
                return {
                    x: dataPoint.time,
                    y: isTime ? dataPoint.value / 1_000_000 : dataPoint.value // nanoseconds to milliseconds
                }
            }),
            borderWidth: 1,
            // borderSkipped: "middle",
            backgroundColor: customColourBackground !== undefined ? customColourBackground : colorBackground ? colorBackground : colorings[i % colorings.length].backgroundColor,
            borderColor: customColourBorder !== undefined ? customColourBorder : colorBorder ? colorBorder : colorings[i % colorings.length].borderColor
        };
        data.push(dataset);
        i++;
    }
    return {
        datasets: data,
    };
}

//	// Required: Start time of when to get the logs in seconds since epoch
// 	StartTime int64 `json:"startTime"`
// 	// Required: End time of when to get the logs in seconds since epoch
// 	EndTime int64 `json:"endTime"`
//
// 	// Temporary:
// 	ServiceName string `json:"serviceName"`
//
// 	// The filters to apply to the logs, so for example, if you want to get logs for a specific service
// 	//you can pass in a filter like {"service_name": ["microservice_a"]}
// 	Filters map[string][]string `json:"filters"`
// 	// Splts is a list of attributes to split the metrics by, for example, if you want to split the metrics by service
// 	// you can pass in a list like ["service_name"]
// 	Splits []string `json:"splits"`
interface GetAllTraceMetricsRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    onlyNumRequests?: boolean;
    environments?: string[];
}

interface GetTraceMetricRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
    aggregate: string;
    functions: MetricFunction[];
    limitResults: boolean;
    bucketSize?: number;
}

class GetAllTraceMetricsResponse {
    constructor(metrics: Metric[]) {
        this.metrics = metrics;
    }

    metrics: Metric[];
}

interface GetLogMetricsRequest {
    startTime: number;
    endTime: number;
    serviceName?: string;
    filters?: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
    abortController?: AbortController;
}

class GetLogMetricsResponse {
    constructor(metric: Metric) {
        this.metric = metric;
    }

    metric: Metric;
}


async function GetLogMetrics(request: GetLogMetricsRequest, abortController: AbortController): Promise<GetLogMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/logMetrics", transformed, {
            signal: abortController.signal
        }
    )
    let awaited = (await d).data;
    return plainToInstance(GetLogMetricsResponse, awaited);
}

async function GetAllTraceMetrics(request: GetAllTraceMetricsRequest, abortController: AbortController): Promise<GetAllTraceMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetrics", transformed, {
            signal: abortController.signal
        }
    )
    let awaited = (await d).data;
    return plainToInstance(GetAllTraceMetricsResponse, awaited);
}

async function GetTraceMetric(request: GetTraceMetricRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetric", transformed,
    )
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}


async function GetMetric(request: GetMetricRequest,
                         abortController: AbortController,
                         setAbortController: React.Dispatch<React.SetStateAction<AbortController>>
): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    abortController.abort()
    let newAbortController = new AbortController();
    setAbortController(newAbortController)
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric", transformed, {
        signal: newAbortController.signal
    })
    d.catch((e) => {
        if (e.name === "AbortError") {
            console.log("Aborted")
        }
    })
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}

async function GetAggregateMetricEvaluation(request: GetMetricAggregateEvaluationRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }

    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric/aggregate", transformed)
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}


const MetricsTest: React.FC = () => {
    return (
        <div className={"w-screen h-screen flex justify-center overflow-y-auto"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex flex-col justify-center h-screen w-screen"}>
                    {/*<MetricSelectorPanel/>*/}
                    <Dashboard/>
                </div>
            </div>
        </div>
    );
}

enum ChartType {
    Bar = "bar",
    Line = "line",
}

enum MetricType {
    Metric = "metric",
    Trace = "trace",
}

function AddChartToDialogComponent(props: {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ChartWidget?: MetricChartWidget,
}) {
    if (!props.ChartWidget) {
        return null
    }
    const [dashboards, setDashboards] = useState<DashboardSearchResponse | undefined>();
    const [dashboardSelected, setDashboardSelected] = useState<DropDownItem | undefined>();

    const selectedItems = dashboardSelected ? [dashboardSelected] : []

    const navigate = usePreserveQueryParamsNavigate();

    useEffect(() => {
        if (!props.isOpen) {
            return
        }
        axios.get<DashboardSearchResponse>("/api/v1/dashboardSearch").then((response) => {
            setDashboards(response.data)
        })
    }, [props.isOpen])

    return <Dialog open={props.isOpen}>
        <DialogContent
            className={"text-textmedium w-1/3 min-w-[400px]"}
            onInteractOutside={() =>
                props.setIsOpen(false)
            }>
            <DialogTitle>
                Add chart to dashboard
            </DialogTitle>
            Select a dashboard to add the chart to:
            <MultiSelectorDropDown selectorSingular={"Dashboard"}
                                   possibleItems={
                                       dashboards?.results.map((dashboard) => {
                                           return {
                                               displayName: dashboard.name,
                                               value: dashboard.id
                                           }
                                       }) || []
                                   }
                                   selectedItems={selectedItems}
                                   setSelectedItems={
                                       (selected) => {
                                           if (selected.length > 0) {
                                               setDashboardSelected(selected[0])
                                           } else {
                                               setDashboardSelected(undefined)
                                           }
                                       }
                                   } isSingleSelect={true}/>
            <Button
                className={"border border-primary bg-primarytransparent"}
                disabled={dashboardSelected === undefined}
                onClick={() => {
                    if (dashboardSelected === undefined) {
                        return
                    }
                    const url = createDashboardUrl(dashboardSelected.value, props.ChartWidget!)
                    // If we're currently on the dashboard, we need to refresh the page when we navigate to the new dashboard
                    if (window.location.pathname === "/dashboard") {
                        window.location.href = url
                    } else {
                        navigate(url)
                    }
                }}>
                Add to dashboard
            </Button>
        </DialogContent>
    </Dialog>
}

function ChartContainer(props: {
    createAlertURL?: string,
    editDialog?: ReactElement,
    styling?: ChartStyling,
    className?: string,
    title?: string,
    children: any,
    dataUsed?: any,
    chartType?: ChartType,
    chartOptions?: any,
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MetricChartWidget | undefined
}) {
    const [selected, setSelected] = useState(props.dataUsed !== undefined && props.dataUsed.datasets !== undefined ? props.dataUsed.datasets : []);
    const [chart, setChart] = useState(props.children);
    const [openAddToDashboardDialog, setOpenAddToDashboardDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();


    useEffect(() => {
        if (props.dataUsed !== undefined && props.dataUsed.datasets !== undefined) {
            setSelected(props.dataUsed.datasets)
        }
    }, [props.dataUsed]);

    useEffect(() => {
        if (selected == null) {
            setChart(props.children)
        } else {
            const selectedData = {datasets: selected}
            switch (props.chartType) {
                case ChartType.Line:
                    setChart(<Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                   data={selectedData} options={props.chartOptions}/>)
                    break;
                case ChartType.Bar:
                    setChart(<Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                  data={selectedData} options={props.chartOptions}/>)
                    break;
            }
        }

    }, [selected, props.children]);

    function getColor(currColor: string, item: any, type: string) {
        if (selected.includes(item)) {
            return currColor
        } else {
            if (type === "bg") {
                return "transparent"
            } else {
                return "rgb(30 41 59)"
            }
        }
    }

    let showLegend = props.hideLegend === undefined ? true : !props.hideLegend;
    // handle edge cases for showing legend
    if (props.dataUsed && props.dataUsed.datasets) {
        if (props.dataUsed.datasets.length === 0) {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label == "") {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label != "") {
            const item = props.dataUsed.datasets[0].label
            const splits = item.split("=").filter((d: string) => d !== "")
            if (splits.length < 2) {
                showLegend = false
            }
        }
    }

    let createUrlElipsis =
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <EllipsisIcon className={"hover:bg-primary rounded hover:cursor-pointer"}
                              size={16}/>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-backgroundmedium rounded">
                {props.createAlertURL && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer">
                    <a
                        onClick={(e) => {
                            navigate(props.createAlertURL!)
                            e.preventDefault()
                        }}
                        href={props.createAlertURL}>Create alert</a>
                </DropdownMenuItem>}
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer"
                    onClick={() => {
                        setOpenAddToDashboardDialog(true)
                    }}
                >
                    Add to dashboard
                </DropdownMenuItem>}
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer"
                    onClick={() => {
                        setSearchParams(prev => {
                            let existing = new URLSearchParams(window.location.search)
                            existing.set("fullscreenChart", encodeURIComponent(JSON.stringify(props.metricChartWidget, dashboardJsonReplacer)))
                            return existing
                        })
                    }}
                >
                    Expand
                </DropdownMenuItem>}
            </DropdownMenuContent>
        </DropdownMenu>
    return (
        <div className={cn("flex flex-col justify-center min-w-0 min-h-0 grow shrink", props.className)}>
            <AddChartToDialogComponent isOpen={openAddToDashboardDialog} setIsOpen={setOpenAddToDashboardDialog}
                                       ChartWidget={props.metricChartWidget}/>
            <div className={"flex justify-center min-w-0 min-h-0 grow shrink"}>
                <div className={"flex-none flex flex-col min-w-0 min-h-0 grow shrink"}>
                    {((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div
                            className={cn("min-w-0 min-h-0 h-[24px] bg-backgroundmedium border rounded-t text-sm font-medium text-textmedium leading-[14px] px-2 py-1 truncate flex justify-between draggablehandle", props.styling?.borderless ? "border-none" : "")}>
                            {
                                props.title !== undefined && props.title !== "" &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.title}
                                </div>
                            }
                            {
                                props.editDialog &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.editDialog}
                                </div>
                            }
                            {
                                // Settings drop down if needed
                                props.createAlertURL &&
                                createUrlElipsis
                            }
                        </div>
                    }
                    {
                        !((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div className={"absolute right-4 mt-1 text-textmedium z-10 mr-1"}>
                            {createUrlElipsis}
                        </div>
                    }
                    <div
                        className={cn((props.title !== undefined && props.title !== "") ? "border-b border-l border-r rounded-b" : "border rounded", "p-1 flex flex-col min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium", props.styling?.borderless ? "border-none" : "", props.className)}
                    >
                        {props.resultsLimited !== undefined && props.resultsLimited && props.actualResultLen !== undefined && props.actualResultLen !== 0 &&
                            <div className={"flex justify-end text-textdark mr-2 hover:cursor-text"}>
                                <div className={"flex items-center gap-1 mr-6"}>
                                    <InfoIcon className={"w-4 h-4"}/>
                                    {props.showAll !== undefined && !props.showAll &&
                                        <div className={"text-sm rounded"}>Showing top 50
                                            of {props.actualResultLen} results.</div>}
                                    {props.showAll !== undefined && !props.showAll && <div
                                        className={"text-sm rounded underline hover:text-primary hover:cursor-pointer"}
                                        onClick={() => {
                                            if (props.setShowAll !== undefined) props.setShowAll(true)
                                        }}>Show all</div>
                                    }
                                </div>
                            </div>}
                        <div
                            className={"p-1 flex min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium border-none"}>
                            {chart}
                        </div>
                        {props.dataUsed && props.dataUsed.datasets && showLegend &&
                            <div id="legend-container"
                                 className={"max-h-[50px] overflow-y-auto ml-4 my-2 no-scrollbar"}>
                                <ul className={"justify-start items-center gap-x-2 inline-flex flex-wrap"}>
                                    {/* Sort by the biggest to smallest*/}
                                    {props.dataUsed.datasets.sort((a: any, b: any) => {
                                        let totalA = a.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        let totalB = b.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        return totalB - totalA
                                    }).map((item: any, index: number) => {
                                        if (item.label === "") {
                                            return null;
                                        }

                                        const splits = item.label.split("=").filter((d: string) => d !== "")
                                        if (splits.length < 2) {
                                            item.label += "[not set]"
                                        }
                                        return (<li key={index}
                                                    className={"flex items-center hover:cursor-pointer hover:bg-backgroundlight hover:text-textlight text-textmedium"}
                                        >
                                        <span className={"border mr-1 w-[13px] h-[13px] inline-block hover:border-2"}
                                              onClick={() => {
                                                  if (selected.includes(item)) {
                                                      setSelected(selected.filter((d: any) => {
                                                          return d !== item
                                                      }))
                                                  } else {
                                                      setSelected([...selected, item])
                                                  }
                                              }}
                                              style={{
                                                  backgroundColor: getColor(item.backgroundColor, item, "bg"),
                                                  borderColor: getColor(item.borderColor, item, "border")
                                              }}></span>
                                            <div className={cn(`text-xs overflow-x-auto text-nowrap no-scrollbar`)}
                                                 onClick={() => {
                                                     if (selected.length === 1 && selected.includes(item)) {
                                                         setSelected(props.dataUsed.datasets)
                                                     } else {
                                                         setSelected([item])
                                                     }
                                                 }}
                                            >{item.label}</div>
                                        </li>)
                                    })}
                                </ul>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getTimeseriesXAxisUnit(startTime: number, endTime: number) {
    let timeRange = (endTime - startTime) / 1000 // ms -> seconds

    if (timeRange < 86400) { // 24 hours
        return "" // default
    }

    if (timeRange < 86400 * 30) { // 30 days
        return "day"
    }

    if (timeRange < 86400 * 30 * 12) { // 1 year
        return "month"
    }

    return "year"
}


Chart.register(zoomPlugin, BarController, PointElement, LineElement, Legend, TimeScale, BarElement, LinearScale, CategoryScale, Title, Tooltip, ChartDataLabels, annotationPlugin, TimeSeriesScale);

function MetoroChart(props: {
    createAlertURL?: string,
    hideOnNoData?: boolean,
    editDialog?: ReactElement,
    className?: string,
    isDuration?: boolean,
    type: ChartType,
    dataToUse: any,
    title?: string,
    styling?: ChartStyling,
    annotations?: any[],
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MetricChartWidget,
    timeUnit?: string,
    timeUnitDisplay?: string,
}) {
    const dispatch = useDispatch();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [timeUnitToUse, setTimeUnitToUse] = useState(props.timeUnit ? props.timeUnit : '');
    const dayTimeFormatterString = getPreferredFormatString([DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes])

    useEffect(() => {
        if (props.timeUnit !== undefined && props.timeUnit !== "") {
            setTimeUnitToUse(props.timeUnit!)
        } else {
            setTimeUnitToUse(getTimeseriesXAxisUnit(timeRange.getStartEnd()[0].getTime(), timeRange.getStartEnd()[1].getTime()))
        }
    }, [timeRange]);


    const options =
        {
            maintainAspectRatio: false,
            color: "#C6D3E2",
            plugins: {
                annotation: {
                    annotations: props.annotations ? props.annotations : []
                },
                zoom: {
                    enabled: true,
                    mode: 'x',
                    zoom: {
                        mode: 'x',
                        onZoomComplete: function ({chart}: any) {
                            const {min, max} = chart.scales.x;
                            let timeRange = new TimeRange(undefined, new Date(min), new Date(max));
                            dispatch(set(new TimeRange(undefined, new Date(min), new Date(max))))
                        },
                        wheel: {
                            enabled: false
                        },
                        pinch: {
                            enabled: false
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 1,
                        },
                    },
                    pan: {
                        enabled: false,
                        mode: 'x'
                    }
                },
                tooltip: {
                    intersect: false,
                    borderColor: "#334670",
                    borderWidth: 1,
                    cornerRadius: 2,
                    backgroundColor: "#1D273F",
                    titleColor: "#C6D3E2",
                    bodyColor: "#C6D3E2",
                    titleAlign: "center",
                    bodyAlign: "center",
                    displayColors: false,
                    padding: 8,
                },
                datalabels: {
                    display: false,
                },
                legend: {
                    display: false, // we use custom HTML Legends
                },
            },
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        color: "#334670"
                    },
                    time: {
                        unit: timeUnitToUse,
                        displayFormats: {
                            minute: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            second: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            hour: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            day: props.timeUnitDisplay ? props.timeUnitDisplay : dayTimeFormatterString,
                        },
                    },
                    ticks: {
                        color: "#C6D3E2",
                        autoSkip: true,
                        maxTicksLimit: 4,
                    },
                    type: 'timeseries',
                    stacked: props.type === ChartType.Bar,
                },
                y: {
                    border: {
                        display: false,
                    },
                    display: true,
                    grid: {
                        drawTicks: true,
                        color: "#334670"
                    },
                    ticks: {
                        callback: function (value: number) {
                            return props.isDuration ? shortEnglishHumanizer(value) : value;
                        },
                        color: "#C6D3E2",
                        maxTicksLimit: 5,
                        autoSkip: true,
                    },
                    stacked: props.type === ChartType.Bar,
                    beginAtZero: true,
                }
            }
        }
    let inner = <div></div>
    if (props.dataToUse === undefined || props.dataToUse.datasets.length === 0) {
        if (props.hideOnNoData) {
            return null;
        }
        inner = <div className={"flex justify-center w-full h-full"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex justify-center text-textmedium text-sm"}>
                    No data to display
                </div>
            </div>
        </div>
    } else {
        switch (props.type) {
            case ChartType.Line:
                inner = <Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                              data={props.dataToUse} options={options}/>
                break;
            case ChartType.Bar:
                inner = <Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                             data={props.dataToUse} options={options}/>
                break;
        }
    }


    return <ChartContainer
        metricChartWidget={props.metricChartWidget}
        createAlertURL={props.createAlertURL}
        editDialog={props.editDialog} styling={props.styling} className={props.className}
        showAll={props.showAll}
        setShowAll={props.setShowAll}
        resultsLimited={props.resultsLimited}
        actualResultLen={props.actualResultLen}
        dataUsed={props.dataToUse}
        chartType={props.type}
        chartOptions={options}
        hideLegend={props.hideLegend}
        title={props.title} children={
        inner
    }/>
}

interface ChartStyling {
    borderless?: boolean;
}

interface MetoroMetricsChartProps {
    // The epoch time in seconds of the start of the time range
    startTime: number;
    // The epoch time in seconds of the end of the time range
    endTime: number;
    // The name of the metric
    metricName: string;
    // The filters to apply to the metric
    filters?: Map<string, string[]>;
    // The filters to exclude from the metric
    excludeFilters?: Map<string, string[]>;
    // The splits to apply to the metric
    splits?: string[];
    // The aggregation to apply to the metric
    aggregation: string;
    // If the metric should be a rate of change
    isRate?: boolean;
    // The title of the chart
    title?: string;
    // The type of the chart
    type: ChartType;
    // Hide on no data
    hideOnNoData?: boolean;
    // Extra styling options for the chart
    styling?: ChartStyling;
    // The size of each datapoint bucket in seconds
    bucketSize?: number;
    // Class name overrides for the chart container on the inside
    className?: string;
    // threshold if defined
    threshold?: Threshold;
    // threshold label if defined
    thresholdLabel?: string;
    // highlight time with vertical line annotations
    timePeriodHighlight?: TimePeriod;
    // Type of the metric to show, could be metric or trace
    metricType: MetricType;
    // hide the legend
    hideLegend?: boolean;
    // Function to apply to the metric, the functions will be applied the same order it appears in the array.
    functions: MetricFunction[];
    // Any variables that are in scope, this will be used to substitute the variables in the filters
    variables?: RuntimeVariable[];
}

export interface AppearanceProps {
    // If not set, its shown. If set to true, it hides the selector
    hideGroupBySelector?: boolean;
    hideFilterSelector?: boolean;
    hideMetricSelector?: boolean;
    hideAggregationSelector?: boolean;
}

export interface TimePeriod {
    start: number;
    end: number;
}

export interface Threshold {
    value: string;
    comparator?: ThresholdComparator;
}

function substituteVariablesInFilters(filters: Map<string, string[]>, variables: RuntimeVariable[] | undefined) {
    if (variables === undefined) {
        return filters;
    }
    // Look through all the values and swap it out with the resolved value of the variable
    let newFilters = new Map<string, string[]>();
    filters.forEach((values, key) => {
        let newValues = values.map(value => {
            let variable = variables.find(variable => "$" + variable.name === value);
            if (variable === undefined) {
                return value;
            }
            if (variable.value === "*") {
                return ""
            }
            return variable.value;
        })
        newFilters.set(key, newValues);
    })
    console.log("newFilters: ",  newFilters)
    return newFilters
}

async function updateChartMetrics(props: MetoroMetricsChartProps,
                                  showAll: boolean,
                                  setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void,
                                  setResultsLimited: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                                  setActualResultLen: (value: (((prevState: number) => number) | number)) => void,
                                  abortController: AbortController,
                                  setAbortController: React.Dispatch<React.SetStateAction<AbortController>>,
                                  variables: RuntimeVariable[]
) {
    let filters = props.filters;
    if (filters === undefined) {
        filters = new Map<string, string[]>();
    }
    // Substitute the variables in the filters
    filters = substituteVariablesInFilters(filters, variables);

    let splits = props.splits;
    if (splits === undefined) {
        splits = [];
    }

    try {
        if (props.metricType === MetricType.Trace) {
            const request: GetTraceMetricRequest = {
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: props.excludeFilters,
                splits: props.splits,
                aggregate: props.aggregation,
                functions: props.functions,
                limitResults: !showAll,
                bucketSize: props.bucketSize
            };
            // Request the Traces endpoint
            const awaitedTraceMetrics = await GetTraceMetric(request);
            setMetric(awaitedTraceMetrics.metric)
            setResultsLimited(awaitedTraceMetrics.isResultLimited)
            setActualResultLen(awaitedTraceMetrics.resultLen);
        } else {
            const request: GetMetricRequest = {
                metricName: props.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: props.excludeFilters,
                splits: splits,
                aggregation: props.aggregation,
                isRate: props.isRate ? props.isRate : false,
                functions: props.functions,
                limitResults: !showAll,
                bucketSize: props.bucketSize
            };
            const awaitedMetrics = await GetMetric(request, abortController, setAbortController);
            setMetric(awaitedMetrics.metric);
            setResultsLimited(awaitedMetrics.isResultLimited);
            setActualResultLen(awaitedMetrics.resultLen);
        }
    } catch (e) {
        console.error(e);
    }
}

const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any[] = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
};

export function createAlertUrlFromAlert(alert: ApiServerAlert): string {
    return "/new-alert?alertJson=" + encodeURIComponent(JSON.stringify(alert));
}


function createDashboardUrl(dashboardId: string, newMetricChartWidgetProps: MetricChartWidget): string {
    return "/dashboard?dashboardId=" + dashboardId + "&addChart=" + encodeURIComponent(JSON.stringify(newMetricChartWidgetProps, dashboardJsonReplacer));
}

function createMetricChartWidgetFromMetricChartProps(props: MetoroMetricsChartProps): MetricChartWidget {
    return {
        position: {
            x: undefined,
            y: undefined,
            w: 6,
            h: 3,
        },
        title: props.title,
        widgetType: 'MetricChart',
        type: props.type,
        metricType: props.metricType,
        metricName: props.metricName,
        filters: props.filters,
        excludeFilters: props.excludeFilters,
        splits: props.splits,
        aggregation: props.aggregation,
        functions: props.functions,
    };
}

export function createMetricChartPropsFromMetricChartWidget(widget: MetricChartWidget, startTime: number, endTime: number): MetoroMetricsChartProps {
    return {
        startTime: startTime,
        endTime: endTime,
        metricName: widget.metricName,
        filters: widget.filters,
        excludeFilters: widget.excludeFilters,
        splits: widget.splits,
        aggregation: widget.aggregation,
        functions: widget.functions,
        title: widget.title,
        type: widget.type,
        metricType: widget.metricType,
    };
}

function createAlertFromMetricChartProps(props: MetoroMetricsChartProps): ApiServerAlert {
    return {
        uuid: "",
        name: "Monitor for " + props.metricName,
        description: "Describe why you want to monitor " + props.metricName,
        destination: {
            type: AlertDestinationType.Slack,
            slackDestination: {
                channel: "help"
            },
        },
        type: props.metricType === MetricType.Metric ? AlertType.Metric : AlertType.Trace,
        // @ts-ignore
        metricAlert: (props.metricType === MetricType.Metric ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(props.filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(props.excludeFilters || new Map<string, string[]>()),
                splits: props.splits || [],
                metricName: props.metricName,
                functions: props.functions || [],
                aggregation: props.aggregation,
            },

            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
        // @ts-ignore
        traceAlert: (props.metricType === MetricType.Trace ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(props.filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(props.excludeFilters || new Map<string, string[]>()),
                splits: props.splits || [],
                aggregation: props.aggregation,
                functions: props.functions || [],
            },
            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
    };
}

function MetoroMetricsChart(props: MetoroMetricsChartProps) {
    const [metric, setMetric] = React.useState<Metric>();
    const [resultsLimited, setResultsLimited] = React.useState<boolean>(false);
    const [actualResultLen, setActualResultLen] = React.useState<number>(0);
    const [showAll, setShowAll] = React.useState<boolean>(false);
    const debouncedUpdateChartMetrics = useDebouncedCallback(updateChartMetrics, 50);
    const [updateChartMetricsAbortController, setUpdateChartMetricsAbortController] = useState<AbortController>(new AbortController());
    const alert = createAlertFromMetricChartProps(props);
    const alertUrl = createAlertUrlFromAlert(alert)
    const metricChartWidget = createMetricChartWidgetFromMetricChartProps(props);

    const [propsState, setPropsState] = useState<MetoroMetricsChartProps>(props);
    useEffect(() => {
        if (!isEqual(props, propsState)) {
            setPropsState(props);
        }
    }, [props]);

    let annotations: any[] = [];

    function getLineStyleForThreshold(threshold: Threshold): any {
        if (threshold.comparator != undefined && threshold.comparator == ThresholdComparator.GreaterThan || threshold.comparator == ThresholdComparator.LessThan) {
            return [5, 5]
        } else {
            return [];
        }
    }

    if (propsState.threshold != undefined && propsState.threshold.value != undefined) {
        // single line on the threshold
        annotations.push({
            type: 'line',
            mode: 'horizontal',
            yMin: propsState.threshold.value,
            yMax: propsState.threshold.value,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: getLineStyleForThreshold(propsState.threshold),
        });
        // shaded area on the threshold
        if (propsState.threshold.comparator != undefined) {
            if (propsState.threshold.comparator == ThresholdComparator.GreaterThan || propsState.threshold.comparator == ThresholdComparator.GreaterThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMin: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            } else if (propsState.threshold.comparator == ThresholdComparator.LessThan || propsState.threshold.comparator == ThresholdComparator.LessThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMax: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            }
        }
    } else if (props.timePeriodHighlight != undefined && !Number.isNaN(props.timePeriodHighlight.start) && !Number.isNaN(props.timePeriodHighlight.end)) {
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.start * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.end * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'box',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderWidth: 0,
        })
    }

    useEffect(() => {
        setShowAll(false)
    }, [propsState.metricName, propsState.splits, propsState.filters, propsState.excludeFilters, propsState.metricType]);

    useEffect(() => {
        debouncedUpdateChartMetrics(propsState, showAll, setMetric, setResultsLimited, setActualResultLen, updateChartMetricsAbortController, setUpdateChartMetricsAbortController, props.variables || []);
    }, [propsState.metricName, propsState.startTime, propsState.endTime, propsState.filters, propsState.excludeFilters, propsState.splits, propsState.aggregation, propsState.type, propsState.title, propsState.metricType, propsState.functions, showAll, props.variables]);

    const durationAggregations = ["p50", "p90", "p95", "p99"];
    const isTime = durationAggregations.includes(propsState.aggregation.toLowerCase());

    const dataToUse = MetricToChartData(metric, isTime);
    return (
        <MetoroChart
            metricChartWidget={metricChartWidget}
            createAlertURL={alertUrl}
            className={propsState.className} hideOnNoData={propsState.hideOnNoData}
            styling={propsState.styling}
            type={propsState.type} dataToUse={dataToUse} title={propsState.title} annotations={annotations}
            hideLegend={propsState.hideLegend} isDuration={isTime} resultsLimited={resultsLimited}
            actualResultLen={actualResultLen} showAll={showAll} setShowAll={setShowAll}/>
    );
}


export {
    MetricToChartData,
    GetAllTraceMetricsResponse,
    GetLogMetricsResponse,
    GetLogMetrics,
    GetAllTraceMetrics,
    GetTraceMetric,
    MetoroMetricsChart,
    GetMetric,
    GetAggregateMetricEvaluation,
    MetricsTest,
    MetoroChart, ChartType, MetricType
};
export type {MetoroMetricsChartProps, Metric};
