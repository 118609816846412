"use client"

import * as React from "react"

import {UserAuthFormRegister} from "./UserAuthFormRegister";
import {UserAuthFormLogin} from "./UserAuthFormLogin";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
    isRegister: boolean;
}

export function UserAuthForm({className, ...props}: UserAuthFormProps) {
    return (
        props.isRegister ? (
            <UserAuthFormRegister/>
        ) : (
            <UserAuthFormLogin/>
        ))
}