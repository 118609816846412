import {Bar} from "react-chartjs-2";
import {cn} from "../ui/lib/utils";

export interface GanttCell {
    startTime: Date;
    endTime: Date;
    color: string;
    text: string;
    metadata?: any;
    onClick: () => void;
}

interface GanttRow {
    label: string;
    cells: GanttCell[];
}

interface GanttData {
    startTime: Date;
    endTime: Date;
    rows: GanttRow[];
    tooltipCallback?: (tooltipItem: any) => any;
    barThickness: number;
    tooltipBodyAlign?: TooltipBodyAlign;
}

export enum TooltipBodyAlign {
    left = "left",
    right = "right",
    center = "center",
}

interface GanttChartProps {
    data: GanttData;
    handleClick?: (event: any) => void;
    type?: string
    axisPosition?: "top" | "bottom" | "left" | "right";
    // hideAxisLabels?: boolean;
}

var heights = [
    "h-[60px]",
    "h-[120px]",
    "h-[180px]",
    "h-[240px]",
    "h-[300px]",
    "h-[360px]",
    "h-[420px]",
    "h-[480px]",
    "h-[540px]",
    "h-[600px]",
    "h-[660px]",
    "h-[720px]",
    "h-[780px]",
    "h-[840px]",
    "h-[900px]",
    "h-[960px]",
    "h-[1020px]",
    "h-[1080px]",
    "h-[1140px]",
    "h-[1200px]",
    "h-[1260px]",
    "h-[1320px]",
    "h-[1380px]",
    "h-[1440px]",
    "h-[1500px]",
    "h-[1560px]",
    "h-[1620px]",
    "h-[1680px]",
    "h-[1740px]",
    "h-[1800px]",
    "h-[1860px]",
    "h-[1920px]",
    "h-[1980px]",
]


function convertGanttDataToChartJsData(data: GanttData) {
    const datasets = []
    let numRows = data.rows.length
    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < data.rows[i].cells.length; j++) {
            let newdata = []
            for (let k = 0; k < numRows; k++) {
                newdata.push(null)
            }
            // Metadata is any extra bits of information about the cell data.
            // It is currently used by podlifecycle chart to display more information about the state.
            if (data.rows[i].cells[j].metadata !== undefined) {
                newdata[i] = [data.rows[i].cells[j].startTime, data.rows[i].cells[j].endTime, data.rows[i].cells[j].metadata]
            } else {
                newdata[i] = [data.rows[i].cells[j].startTime, data.rows[i].cells[j].endTime]
            }

            datasets.push({
                label: data.rows[i].cells[j].text,
                data: newdata,
                backgroundColor: data.rows[i].cells[j].color,
                onClick: data.rows[i].cells[j].onClick,
            })
        }
    }
    return {
        labels: data.rows.map(row => row.label),
        datasets: datasets,
        tooltipCallback: data.tooltipCallback
    }
}

function GanttChart(props: GanttChartProps) {
    const chartData = convertGanttDataToChartJsData(props.data)

    var myChart = <Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"} data={{
        labels: chartData.labels,
        datasets: chartData.datasets
    }}
                       options={{
                           animation: false,
                           maintainAspectRatio: false,
                           barThickness: props.data.barThickness,
                           color: "#C6D3E2",
                           responsive: true,
                           indexAxis: 'y',
                           plugins: {
                               tooltip: {
                                   enabled: props.data.tooltipCallback !== undefined,
                                   intersect: false,
                                   borderColor: "#334670",
                                   borderWidth: 1,
                                   cornerRadius: 2,
                                   backgroundColor: "#1D273F",
                                   titleColor: "#C6D3E2",
                                   bodyColor: "#C6D3E2",
                                   bodyFont: {size: 12, family: "Inter", weight: "normal"},
                                   position: "nearest",
                                   titleAlign: "center",
                                   xAlign: "center",
                                   yAlign: "top",
                                   bodyAlign: props.data.tooltipBodyAlign ? props.data.tooltipBodyAlign : "center",
                                   displayColors: false,
                                   usePointStyle: false,
                                   padding: 8,
                                   callbacks: {
                                       label: props.data.tooltipCallback ?
                                           function (context: any) {
                                               return props.data.tooltipCallback!(context)
                                           } : function (context: any) {
                                               return ""
                                           }
                                   }
                               },
                               datalabels: {
                                   display: false,
                               },
                               legend: {
                                   display: false,
                                   position: 'top',
                               },
                           },
                           annotation: {
                               annotations: [],
                           },
                           scales: {
                               y: {
                                   stacked: true,
                                   ticks: {
                                       color: "#C6D3E2",
                                       // display: props.hideAxisLabels != undefined ? !props.hideAxisLabels : true,
                                   },
                                   // display: props.hideAxisLabels != undefined ? !props.hideAxisLabels : true,
                               },
                               x: {
                                   type: 'time',
                                   ticks: {
                                       color: "#C6D3E2",
                                       maxTicksLimit: 4,
                                       // display: props.hideAxisLabels != undefined ? !props.hideAxisLabels : true,
                                   },
                                   // display: props.hideAxisLabels != undefined ? !props.hideAxisLabels : true,
                                   time: {
                                       // Luxon format string
                                       tooltipFormat: 'DD'
                                   },
                                   // @ts-ignore
                                   min: props.data.startTime,
                                   // @ts-ignore
                                   max: props.data.endTime,
                                   position: props.axisPosition ? props.axisPosition : 'bottom',
                               }
                           },
                           onClick: props.handleClick
                       }}
    />
    return (
        <div className={cn("text-textmedium relative", heights[chartData.labels.length - 1])}>
            {myChart}
        </div>
    )
}


export {GanttChart}