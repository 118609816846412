import {useEffect, useRef} from 'react';

const useScriptRef = () => {
    const ref = useRef(true);
    useEffect(
        () => () => {
            ref.current = false;
        },
        []
    );

    return ref;
};

export default useScriptRef;
