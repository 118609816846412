import React from "react";
import {getLogLeveLIndicatorColor} from "./utils";
import {format} from "date-fns";


function LogHeader(props: {
    logLevel: string,
    datetime: number
}) {
    const logLevelColor = getLogLeveLIndicatorColor(props.logLevel);
    return (
        <div className="w-full grow h-[22px] justify-start items-center gap-4 inline-flex">
            <div className={"p-1 rounded justify-center items-center flex " + logLevelColor}>
                <div className="text-textlight text-sm font-medium leading-[14px]">{props.logLevel.toUpperCase()}</div>
            </div>
            <div className="self-stretch justify-start items-center gap-2.5 flex">
                <div className="whitespace-nowrap text-textmedium text-sm font-medium leading-[14px]">
                    {format(new Date(props.datetime), "PPPppp")}
                </div>
            </div>
        </div>
    );
}

export function LogMessage(props: {
    colourBandOverride?: string,
    hideButtons?: boolean,
    logLevel: string,
    logMessage: string
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    let logLevelIndicatorColor = getLogLeveLIndicatorColor(props.logLevel)
    if (props.colourBandOverride !== undefined) {
        logLevelIndicatorColor = props.colourBandOverride
    }
    return <div
        className="w-full grow min-h-[15vh] pr-1 max-h-64 bg-backgroundmedium rounded justify-start items-start gap-4 inline-flex">
        <div className="w-[3px] relative h-full">
            <div className={"w-[3px] h-full left-0 top-0 absolute rounded " + logLevelIndicatorColor}/>
        </div>
        <div className="w-full h-full p-3 justify-center items-center relative">
            {!props.hideButtons && <div className="absolute top-[-42px] right-[-4px] p-1 flex  gap-2">
                {/*    Icons to include or exclude log lines*/}
                <div
                    className="cursor-pointer text-textmedium bg-secondarytransparenter border-secondary border rounded p-1"
                    onClick={() => {
                        // If the log message is already in the regexes, don't add it again
                        let searchElement = props.logMessage;
                        // Escape re2 special characters
                        searchElement = searchElement.replace(/['-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                        if (props.regexes.includes(searchElement)) {
                            return;
                        }

                        // If the log message is in the exclude regexes, remove it from there
                        if (props.excludeRegexes.includes(searchElement)) {
                            let regexes = [...props.excludeRegexes]
                            regexes = regexes.filter((regex) => regex !== searchElement);
                            props.setExcludeRegexes(regexes);
                        }

                        let regexes = [...props.regexes]
                        regexes.push(searchElement);
                        props.setRegexes(regexes);
                    }}>
                    Include in search
                </div>
                <div className="cursor-pointer text-textmedium bg-redtransparenter border-red-500 border rounded p-1"
                     onClick={() => {
                         // If the log message is already in the exclude regexes, don't add it again
                         let searchElement = props.logMessage;
                         // Escape re2 special characters
                         searchElement = searchElement.replace(/['-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                         if (props.excludeRegexes.includes(searchElement)) {
                             return;
                         }

                         // If the log message is in the regexes, remove it from there
                         if (props.regexes.includes(searchElement)) {
                             let regexes = [...props.regexes]
                             regexes = regexes.filter((regex) => regex !== searchElement);
                             props.setRegexes(regexes);
                         }
                         let regexes = [...props.excludeRegexes]
                         regexes.push(searchElement);
                         props.setExcludeRegexes(regexes);
                     }}>
                    Exclude from search
                </div>
            </div>}
            <div
                className="w-full grow h-full break-all text-textmedium text-sm font-medium leading-[14px] overflow-x-clip overflow-y-auto text-pretty">
                {props.logMessage}
            </div>
        </div>
    </div>
}

function LogMessageContainer(props: {
    overrideColourBand?: string,
    hideExcludeSearchButtons?: boolean,
    logLevel: string,
    logMessage: string,
    datetime: number,
    regexes: string[],
    excludeRegexes: string[],
    setRegexes: (regexes: string[]) => void,
    setExcludeRegexes: (regexes: string[]) => void
}) {

    let log = props.logMessage;
    // Let's pretty print the log message if it's a JSON object
    // This doesnt work very well right now, but it's a good starting point
    try {
        const parsedLog = JSON.parse(props.logMessage);
        log = JSON.stringify(parsedLog, null, 2);
    } catch (e) {
        // Do nothing
    }


    return (<div className="w-full flex-col justify-start items-start gap-16 inline-flex">
            <div className="flex-col justify-start items-start flex">
                <LogHeader logLevel={props.logLevel} datetime={props.datetime}/>
            </div>
            <LogMessage
                hideButtons={props.hideExcludeSearchButtons}
                colourBandOverride={props.overrideColourBand}
                regexes={props.regexes}
                excludeRegexes={props.excludeRegexes}
                logLevel={props.logLevel} logMessage={log}
                setRegexes={props.setRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
            />
        </div>
    )
}

export default LogMessageContainer;