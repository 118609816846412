import React, {ReactElement, useEffect} from "react";
import {K8sPodDetails} from "./model";
import * as k8s from "@kubernetes/client-node";
import {V1Pod} from "@kubernetes/client-node";
import yaml from "js-yaml";
import {GripIcon, MilestoneIcon, ServerCogIcon, ServerIcon} from "lucide-react";
import {Tag} from "../SidePanel/Tags/TagContainer";
import hdate from "human-date";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import Containers from "./ContainerDetails";
import PrettyYamlViewer from "./YamlViewer";
import {Badge} from "../ui/badge";
import timerange from "../../store/reducers/timerange";
import {useSelector} from "react-redux";
import Logs from "../../pages/Logs";
import {ChartType, MetoroMetricsChart, MetricType} from "../../pages/MetricsTest";
import Traces from "../../pages/Traces";
import {useSearchParams} from "react-router-dom";
import {cn, usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import {RateFunctionType} from "../Dashboarding/widgets/MetricSelector";


export function PodItemSideView(props: {
    podDetails: K8sPodDetails
    indicatorColor: string
    serviceName?: string
    nodeName?: string
    podStatus: string
}) {
    // Parse the YAML content
    const parsedYAML = yaml.load(props.podDetails.yaml);
    const k8sPod = parsedYAML as k8s.V1Pod;
    let restartCount = 0;
    k8sPod.status?.containerStatuses?.forEach((containerStatus) => {
        restartCount += containerStatus.restartCount;
    });
    const hostIP = k8sPod.status?.hostIP!;
    const creationTimestamp = k8sPod.metadata?.creationTimestamp

    return (
        <div data-vaul-no-drag
             className="h-full p-4 bg-backgrounddark shadow flex-col justify-start items-center gap-4 inline-flex border-l">
            <div className={"w-full flex flex-col justify-between gap-4 select-text"}>
                <div className="justify-start items-center gap-2 inline-flex">
                    <MilestoneIcon className="text-secondary w-8 h-[42px] relative"/>
                    <div className="pb-[8px] self-stretch justify-start items-center gap-2 flex">
                        <div className="text-textdark text-sm font-semibold leading-tight text-nowrap">Created on
                        </div>
                        <div
                            className="text-textdark text-sm font-semibold leading-tight text-nowrap overflow-x-clip">{hdate.prettyPrint(creationTimestamp, {showTime: true})}</div>
                    </div>
                </div>
                <div className={"flex justify-between"}>
                    <div className="w-[350px] h-full flex-col justify-start items-start inline-flex">
                        <Tag tagKey={"podName"} tagValue={props.podDetails.name} borderColour={"border-secondary"}/>
                    </div>
                    <div className={`px-1 py-2 ${props.indicatorColor} rounded justify-center items-center flex`}>
                        <div
                            className="text-textlight text-sm font-medium px-4">{props.podStatus}</div>
                    </div>
                </div>
            </div>
            <div className={"w-full border rounded"}>
                <PodOverview serviceName={props.serviceName} nodeName={props.nodeName} restartCount={restartCount} hostIP={hostIP}/>
            </div>
            <TabView podDetails={props.podDetails} podStatus={props.podStatus} serviceName={props.serviceName} nodeName={props.nodeName}/>
        </div>
    )
}

function PodOverview(props: {
    serviceName?: string,
    nodeName?: string,
    restartCount: number,
    hostIP: string
}) {
    return <div className={"w-full grid grid-cols-3 grid-rows-1 px-4 py-4 bg-backgroundmedium"}>
        <div className={"flex justify-begin"}>
            <div className={"flex-col justify-center space-y-2"}>
                <div className={"flex gap-2"}>
                    <ServerCogIcon className={"text-primary h-[20px] w-[20px]"}/>
                    <div className={"text-textdark"}>{props.serviceName ? "Service Name" : "Node Name"}</div>
                </div>
                <div className={"text-textmedium"}>{props.serviceName ? props.serviceName : props.nodeName} </div>
            </div>
        </div>
        <div className={"flex justify-center"}>
            <div className={"flex-col justify-center items-center space-y-2"}>
                <div className={"flex gap-1"}>
                    <GripIcon className={"text-primary h-[20px] w-[20px]"}/>
                    <div className={"text-textdark"}>Restarts</div>
                </div>
                <div className={"flex justify-center"}>
                    <div className={"text-textmedium"}>{props.restartCount} </div>
                </div>
            </div>
        </div>
        <div className={"flex justify-end"}>
            <div className={"flex-col justify-center items-center space-y-2"}>
                <div className={"flex gap-1"}>
                    <ServerIcon className={"text-primary h-[20px] w-[20px]"}/>
                    <div className={"text-textdark"}>Node IP</div>
                </div>
                <div className={"text-textmedium"}>{props.hostIP} </div>
            </div>
        </div>
    </div>
}

function TabView(props: {
    serviceName?: string
    nodeName?: string
    podDetails: K8sPodDetails
    podStatus: string
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = React.useState<string>(searchParams.get("podTab") || "metadata");

    useEffect(() => {
        let tab = searchParams.get("podTab");
        if (currentTab !== tab) {
            let search = new URLSearchParams(searchParams);
            search.set("podTab", currentTab);
            setSearchParams(search);
        }
    }, [currentTab]);

    const parsedYaml = yaml.load(props.podDetails.yaml)
    const k8sPod = parsedYaml as V1Pod;
    const containerIds: string[] = [];
    if (k8sPod.status && k8sPod.status.containerStatuses && k8sPod.status.containerStatuses.length > 0) {
        k8sPod.status.containerStatuses.forEach((containerStatus) => {
            if (containerStatus.containerID !== undefined) {
                containerIds.push(containerStatus.name);
            }
        });
    }
    let namespace = "default"
    if (k8sPod.metadata !== undefined) {
        namespace = k8sPod.metadata.namespace ? k8sPod.metadata.namespace : "default";
    }

    // @ts-ignore
    return <Tabs disabledTabClassName={"hidden"}
                 onValueChange={(val) => setCurrentTab(val)}
                 value={currentTab}
                 defaultValue="metadata"
                 className="w-full flex flex-col grow shrink min-h-0 min-w-0">
        <TabsList className={cn("w-full flex-none mb-4 grid grid-rows-1 border", props.serviceName ? "grid-cols-6" : "grid-cols-4")}>
            <TabsTrigger value="metadata">Metadata</TabsTrigger>
            <TabsTrigger value="metrics">Metrics</TabsTrigger>
            <TabsTrigger value="container">Containers</TabsTrigger>
            <TabsTrigger value="yaml">YAML</TabsTrigger>
            {props.serviceName && <TabsTrigger value="logs">Logs</TabsTrigger>}
            {props.serviceName &&<TabsTrigger value="traces">Traces</TabsTrigger>}
        </TabsList>
        <div className={"overflow-y-auto flex grow shrink justify-start min-h-0 min-w-0"}>
            <TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0"} value="metadata">
                <div>
                    <PodDetailsTab podDetail={k8sPod} podStatus={props.podStatus} namespace={namespace}
                                   podName={props.podDetails.name}/>
                </div>
            </TabsContent>

            <TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0"} value="metrics">
                <div>
                    <PodMetricsTab podDetail={k8sPod} podStatus={props.podStatus} namespace={namespace}
                                   podName={props.podDetails.name}/>
                </div>
            </TabsContent>
            <TabsContent className={"flex grow shrink min-h-0 min-w-0"} value="container">
                {containerIds && containerIds.length > 0 &&
                    <Containers podK8sObj={k8sPod} namespace={namespace} podDetails={props.podDetails}
                                podStatus={props.podStatus}/>}
                {containerIds && containerIds.length == 0 &&
                    <div className={"flex justify-center items-center h-32 text-textdark"}>No containers found</div>}
            </TabsContent>
            <TabsContent className={"flex grow shrink min-h-0 min-w-0"} value="yaml">
                <PrettyYamlViewer yamlString={props.podDetails.yaml}/>
            </TabsContent>
            {props.serviceName && <TabsContent className={"flex grow shrink min-h-0 min-w-0"} value="logs">
                <EmbedLogs namespace={namespace} serviceName={props.serviceName} containerNames={containerIds}
                           podName={props.podDetails.name}/>
            </TabsContent>}
            {props.serviceName && <TabsContent className={"flex grow shrink min-h-0 min-w-0"} value="traces">
                <EmbedTraces namespace={namespace} serviceName={props.serviceName} containerNames={containerIds}
                             podName={props.podDetails.name}/>
            </TabsContent>}
        </div>
    </Tabs>
}

function EmbedLogs(props: { namespace: string, serviceName: string, containerNames: string[], podName: string }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();
    let formattedContainerIds = props.containerNames.map((containerName) => {
        return `/k8s/${props.namespace}/${props.podName}/${containerName}`
    })

    useEffect(() => {
        setSearchParams(prev => {
            let existing = new URLSearchParams(prev)
            let filter = new Map<string, string[]>();
            filter.set("service.name", [props.serviceName]);

            filter.set("container.id", formattedContainerIds);
            existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
            return existing
        })
    }, [searchParams]);

    return <div className={"flex min-h-0 min-w-0 grow shrink"}>
        <Logs removeService={true} justLogsFeed={true} expandOnClick={() => {
            let existing = new URLSearchParams(window.location.search)
            const currentServiceName = existing.get("service")
            const filterBy = {
                "service.name": [currentServiceName],
                "container.id": formattedContainerIds
            }
            navigate(`/logs?filter=${JSON.stringify(filterBy)}`)
        }}/>
    </div>
}

function EmbedTraces(props: { namespace: string, serviceName: string, containerNames: string[], podName: string }) {
    let filter = new Map<string, string[]>();
    filter.set("server.service.name", [props.serviceName]);
    let formattedContainerIds = props.containerNames.map((containerName) => {
        return `/k8s/${props.namespace}/${props.podName}/${containerName}`
    })
    filter.set("server.container.id", formattedContainerIds);
    return <div className={"flex grow shrink min-h-0 gap-4 min-w-0 max-w-full"}>
        <Traces filter={filter} removeService={true} justTraceFeed={true}/>
    </div>
}

function PodDetailsTab(props: {
    podDetail: V1Pod
    podStatus: string
    namespace: string
    podName: string
}) {
    const ownerReferences = props.podDetail.metadata?.ownerReferences;
    let ownerNames: string[] = []
    if (ownerReferences && ownerReferences.length > 0) {
        ownerNames = ownerReferences.map((owner) => {
            return owner.name
        })
    }
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();

    let conditions: ReactElement[] = [];
    props.podDetail.status?.conditions?.forEach((condition, index) => {
        conditions.push(<Tag tagKey={condition.type} tagValue={condition.status}
                             tooltipContent={condition.reason ? condition.reason : condition.message}/>);
    });

    let filters = new Map<string, string[]>();
    let containerIds: string[] = [];
    props.podDetail.status?.containerStatuses?.forEach((containerStatus) => {
        containerIds.push(`/k8s/${props.namespace}/${props.podName}/${containerStatus.name}`);
    })
    filters.set("container_id", containerIds)

    return (
        <div className={"flex flex-col space-y-8"}>
            <div className="p-4 flex flex-col gap-4 w-full bg-backgroundmedium border rounded">
                <div className={"text-lg text-textmedium"}>
                    Metadata
                </div>
                <div className={"flex flex-col space-y-8"}>
                    <div className={"flex flex-row space-x-8"}>
                        <div className={"text-textmedium font-bold text-sm w-[128px] flex-none "}>Status</div>
                        <div className={"text-textmedium text-sm"}>{props.podStatus}</div>
                    </div>
                    {ownerReferences && ownerReferences.length > 0 &&
                        <div className={"flex flex-row space-x-8"}>
                            <div className={"text-textmedium font-bold text-sm w-[128px] flex-none "}>Controlled
                                by
                            </div>
                            <div className={"text-textmedium text-sm flex-none"}>{ownerNames.map((ownerName) => {
                                return <Badge
                                    className={"bg-primarytransparent text-sm border-primary text-textlight rounded"}>{ownerName}</Badge>
                            })}</div>
                        </div>
                    }
                    <div className={"flex flex-row space-x-8"}>
                        <div className={"text-textmedium font-bold text-sm w-[128px] flex-none "}>Node IP</div>
                        <div className={"text-textmedium text-sm"}>{props.podDetail.status?.hostIP}</div>
                    </div>
                    <div className={"flex flex-row space-x-8"}>
                        <div className={"text-textmedium font-bold text-sm w-[128px] flex-none "}>Pod IPs</div>
                        <div className={"text-textmedium text-sm"}>{props.podDetail.status?.podIPs?.map((ip) => {
                            return <div>{`${ip.ip} `}</div>
                        })}</div>
                    </div>
                    <div className={"flex flex-row space-x-8 "}>
                        <div className={"text-textmedium font-bold text-sm w-[128px] flex-none "}>Conditions
                        </div>
                        <div className={"text-textmedium gap-y-2 flex flex-wrap gap-x-2"}>{conditions}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function PodMetricsTab(props: {
    podDetail: V1Pod
    podStatus: string
    namespace: string
    podName: string
}) {
    const ownerReferences = props.podDetail.metadata?.ownerReferences;
    let ownerNames: string[] = []
    if (ownerReferences && ownerReferences.length > 0) {
        ownerNames = ownerReferences.map((owner) => {
            return owner.name
        })
    }
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();

    let conditions: ReactElement[] = [];
    props.podDetail.status?.conditions?.forEach((condition, index) => {
        conditions.push(<Tag tagKey={condition.type} tagValue={condition.status}
                             tooltipContent={condition.reason ? condition.reason : condition.message}/>);
    });

    let filters = new Map<string, string[]>();
    filters.set("pod_name", [`/k8s/${props.namespace}/${props.podName}`])

    return (
        <div className={"flex flex-col space-y-8"}>
            <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto"}>
                <MetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_cpu_usage_seconds_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`CPU Usage - Seconds`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    hideOnNoData={true}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_cpu_limit_cores"}
                    functions={[]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`CPU Limit - Cores`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_memory_rss_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Usage - Bytes`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_memory_limit_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Limit - Bytes`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_disk_read_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Read`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_disk_written_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Written`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_resources_disk_used_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Usage`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_net_tcp_active_connections"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Open TCP Connections`}
                    splits={["container_id"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_net_bytes_received_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Received`}
                    splits={["pod_name"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_net_bytes_sent_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Sent`}
                    splits={["pod_name"]}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
            </div>
        </div>
    )
}