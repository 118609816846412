import {BaseView} from "./BaseView";
import {MetricQuerySelector, RateFunctionType} from "../components/Dashboarding/widgets/MetricSelector";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ChartType, MetoroMetricsChart, MetoroMetricsChartProps, MetricType} from "./MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import axios from "../utility/customAxios";
import {Drawer} from "vaul";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import CopyToClipboardButton from "../components/CopyToClipboardButton";
import PrettyYamlViewer from "../components/K8sInfo/YamlViewer";
import yaml from "js-yaml";
import {V1Node} from "@kubernetes/client-node";
import {Collapsible, CollapsibleContent} from "components/ui/collapsible";
import {CollapsibleTrigger} from "../components/ui/collapsible";
import {ChevronDown, ChevronRight, InfoIcon} from "lucide-react";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {useDebouncedCallback} from "use-debounce";
import {getPods, GetPodsResponse, MetricsPanel} from "../components/K8sInfo/KubernetesView";
import PodsTable from "../components/K8sInfo/PodsTable";
import {Progress} from "../components/ui/progress";
import hdate from "human-date";
import {useSearchParams} from "react-router-dom";
import {dashboardJsonReplacer, dashboardJsonReviver} from "../components/Dashboarding/Dashboard";

interface Node {
    name: string
    environment: string
    status: string
    yaml: string
    labels: Map<string, string>
    creationTimestamp: number
    nodeStats: NodeStats
}

interface NodeStats {
    cpuUtil: number
    memoryUtil: number
}

function Infrastructure() {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    const [nodes, setNodes] = useState<Node[]>([]);
    const debouncedUpdateNodes = useDebouncedCallback(updateNodes, 10);
    const [searchParams, setSearchParams] = useSearchParams();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [ascending, setAscending] = useState<boolean>(true);
    const [sortCategory, setSortCategory] = useState<string>("");

    const [chartProps, setChartProps] = useState<MetoroMetricsChartProps>({
        metricType: MetricType.Metric,
        startTime: Math.floor(startEnd[0].getTime() / 1000),
        endTime: Math.floor(startEnd[1].getTime() / 1000),
        aggregation: "max",
        title: "",
        metricName: "node_resources_cpu_usage_seconds_total",
        functions: [{id: "1", functionType: RateFunctionType.MonotonicDifference}],
        filters: searchParams.get("filters") ? JSON.parse(searchParams.get("filters")!, dashboardJsonReviver) : new Map<string, string[]>(),
        excludeFilters: searchParams.get("excludeFilters") ? JSON.parse(searchParams.get("excludeFilters")!, dashboardJsonReviver) : new Map<string, string[]>(),
        splits: searchParams.get("splits") ? JSON.parse(searchParams.get("splits")!, dashboardJsonReviver) : [],
        type: ChartType.Line,
    });

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("filters", JSON.stringify(chartProps.filters, dashboardJsonReplacer))
            return prev;
        })
    }, [chartProps.filters]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("excludeFilters", JSON.stringify(chartProps.excludeFilters, dashboardJsonReplacer))
            return prev;
        })
    }, [chartProps.excludeFilters]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("splits", JSON.stringify(chartProps.splits, dashboardJsonReplacer))
            return prev;
        })
    }, [chartProps.splits]);


    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null && environments !== "") {
            setEnvironments([environments])
        }
        if (environments === "") {
            setEnvironments([])
        }
    }, [searchParams])

    useEffect(() => {
        setChartProps((prev) => {
            let filtersToUse = prev.filters ?? new Map<string, string[]>();
            if (environments.length > 0 && environments[0] !== "") {
                let envToUse = environments.filter((env) => env !== "")
                filtersToUse.set("environment", envToUse)
            } else {
                filtersToUse.delete("environment")
            }
            return {
                ...prev,
                filters: filtersToUse
            }
        })
    }, [environments]);

    async function updateNodes(startTime: number,
                               endTime: number,
                               environments: string[],
                               filters: Map<string, string[]>,
                               excludeFilters: Map<string, string[]>,
                               splits: string[],
                               setNodes: Dispatch<SetStateAction<Node[]>>) {

        try {
            const request = {
                startTime: startTime,
                endTime: endTime,
                environments: environments,
                filters: Object.fromEntries(filters),
                excludeFilters: Object.fromEntries(excludeFilters),
                splits: splits
            };
            const awaitedNodes = await axios.post("/api/v1/infrastructure/nodes", request);
            setNodes(awaitedNodes.data.nodes);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        let isEmptyEntry = false;
        if (chartProps.filters !== undefined) {
            isEmptyEntry = Array.from(chartProps.filters?.values()).filter((value) => value.includes("")).length > 0;
        }
        if (chartProps.filters && isEmptyEntry) {
            return
        }
        debouncedUpdateNodes(
            Math.floor(startEnd[0].getTime() / 1000),
            Math.floor(startEnd[1].getTime() / 1000),
            environments,
            chartProps.filters ? chartProps.filters : new Map(),
            chartProps.excludeFilters ? chartProps.excludeFilters : new Map(),
            chartProps.splits ? chartProps.splits : [],
            setNodes
        )
    }, [timeRange, chartProps.filters, chartProps.excludeFilters, chartProps.splits, environments]);

    useEffect(() => {
        setNodes(prev => {
            return [...prev].sort((a, b) => {
                const valueA = sortCategory === 'cpu' ? a.nodeStats.cpuUtil : a.nodeStats.memoryUtil;
                const valueB = sortCategory === 'cpu' ? b.nodeStats.cpuUtil : b.nodeStats.memoryUtil;
                return ascending ? valueA - valueB : valueB - valueA;
            });
        });
    }, [ascending, sortCategory]);

    return <BaseView title={"Infrastructure"} disableClusterSelector={false}>
        <div className={"flex flex-col w-full pl-4 pr-4 pt-4 gap-4 overflow-y-auto"}>
            <div className={"flex items-start"}>
                <MetricQuerySelector setChartProps={setChartProps}
                                     displaySettings={{
                                         showGroupBy: true,
                                         showAggregation: false,
                                         showFilters: true,
                                         showMetricName: false
                                     }}
                                     chartProps={chartProps} removeAttributes={[
                    "__name__", "job", "mode", "system_uuid", "instance" // HACK HACK: Remove these attributes from the metric selector.
                ]}/>
            </div>
            <NodeTable nodes={nodes}
                       setAscending={(ascended: boolean, category: string) => {
                           setAscending(ascended)
                           setSortCategory(category)
                       }}
                       ascending={ascending}
                       sortCategory={sortCategory}
            />
            <HostMetrics filters={chartProps.filters} splits={chartProps.splits}
                         excludeFilters={chartProps.excludeFilters}/>
        </div>
    </BaseView>
}


function NodeTable(props: {
    nodes: Node[],
    ascending: boolean,
    sortCategory: string,
    setAscending: (ascending: boolean, category: string) => void
}) {
    function getSortIcons(category: string) {
        return (
            <div onClick={() => props.setAscending(!props.ascending, category)}
                 className="hover:cursor-pointer text-sm pr-4">
                {props.sortCategory === category ? (props.ascending ? '▲' : '▼') : '▲▼'}
            </div>
        );
    }

    return <div
        className="min-w-0 min-h-[200px] bg-backgroundmedium border rounded flex flex-col overflow-y-auto overflow-x-hidden no-scrollbar">
        <div className={"flex justify-start"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start flex grow shrink">
                <div
                    className={`flex-none w-[370px] h-full leading-8 text-textmedium font-semibold`}>
                    <div className={"flex gap-4 items-center"}>
                        <div>Hostname</div>
                        <div
                            className={"border rounded border-primary bg-backgrounddark px-2 py-1 text-textmedium text-sm"}> {(props.nodes === null || props.nodes.length == 0) ? "no results" : (props.nodes.length > 1 ? `${props.nodes.length} results` : `${props.nodes.length} result`)}
                        </div>
                    </div>
                </div>
                <div
                    className={`flex-none w-[160px] leading-8 text-textmedium truncate font-semibold`}>Status
                </div>
                <div
                    className={`flex-none w-[200px] leading-8 text-textmedium truncate font-semibold`}>Environment
                </div>
                <div
                    className={`flex-none w-[200px] leading-8 text-textmedium truncate font-semibold`}>
                    <div className={"flex gap-2 items-center"}>
                        <Tooltip>
                            <TooltipTrigger><InfoIcon className={"w-3 h-3"}/></TooltipTrigger>
                            <TooltipContent>CPU utilization calculated from the /proc/stat linux subsystem on the
                                node. </TooltipContent>
                        </Tooltip>
                        <div>CPU</div>
                        {getSortIcons("cpu")}
                    </div>

                </div>
                <div
                    className={`flex-none w-[200px] leading-8 text-textmedium truncate font-semibold`}>
                    <div className={"flex gap-2 items-center"}>
                        <Tooltip>
                            <TooltipTrigger><InfoIcon className={"w-3 h-3"}/></TooltipTrigger>
                            <TooltipContent>Memory utilization calculated from the /proc/meminfo linux subsystem on the
                                node. </TooltipContent>
                        </Tooltip>
                        <div>Memory</div>
                        {getSortIcons("memory")}
                    </div>
                </div>
                <div
                    className={`flex-none w-[200px] leading-8 text-textmedium truncate font-semibold`}>Created
                </div>
            </div>
        </div>
        <div
            className="h-full max-w-full min-w-0 min-h-0 overflow-y-auto scrollMedium border-t rounded-b flex flex-col grow shrink overflow-x-hidden">
            {
                props.nodes && props.nodes.length === 0 &&
                <div className="h-full w-full flex justify-center items-center">
                    <div className="text-textmedium text-lg">No hosts found</div>
                </div>
            }
            {
                props.nodes && props.nodes.map((node) => {
                    return <ExpandableEntry nodeSummary={node}
                    />
                })
            }
        </div>
    </div>
}

function ExpandableEntry(props: {
    nodeSummary: Node
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    const [node, setNode] = useState<Node>();
    const [k8sNode, setK8sNode] = useState<V1Node>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    function getNodeDetails(nodeName: string) {
        axios.get(`/api/v1/infrastructure/node?nodeName=${nodeName}&startTime=${Math.floor(startEnd[0].getTime() / 1000)}`).then((response) => {
            setNode(response.data.node)
            const parsedYaml = yaml.load(response.data.node.yaml)
            const parsedK8sNode = parsedYaml as V1Node;
            setK8sNode(parsedK8sNode)
            setOpenDialog(true)
        }).catch((error) => {
            console.error(error)
        })
    }

    if (props.nodeSummary === undefined || props.nodeSummary === null) {
        return <div></div>
    }

    function getCPUAvailable(): string {
        const nodeObjs = yaml.load(props.nodeSummary.yaml) as V1Node;
        return nodeObjs.status?.capacity ? nodeObjs.status.capacity["cpu"] : ""
    }

    function getMemoryAvailable(): string {
        const nodeObjs = yaml.load(props.nodeSummary.yaml) as V1Node;
        return nodeObjs.status?.capacity ? nodeObjs.status.capacity["memory"] : ""
    }

    let cpuUtil = 0;
    if (props.nodeSummary && props.nodeSummary.nodeStats && props.nodeSummary.nodeStats.cpuUtil) {
        cpuUtil = props.nodeSummary.nodeStats.cpuUtil;
    }
    let memoryUtil = 0;
    if (props.nodeSummary && props.nodeSummary.nodeStats && props.nodeSummary.nodeStats.memoryUtil) {
        memoryUtil = props.nodeSummary.nodeStats.memoryUtil;
    }

    return <Drawer.Root direction="right" open={openDialog}>
        <Drawer.Trigger asChild onClick={() => getNodeDetails(props.nodeSummary.name)}>
            {props.nodeSummary.name != "" && props.nodeSummary.environment != "" && <div
                className={"pl-4 flex py-2 items-center justify-start hover:bg-backgroundlight hover:cursor-pointer h-[48px]"}
                onClick={() => getNodeDetails(props.nodeSummary.name)}
            >
                <div className={"flex-none text-textmedium w-[370px]"}>{props.nodeSummary.name}</div>
                <div className={"flex-none w-[160px]"}>
                    <div
                        className={"w-max border rounded py-1 px-2 bg-secondarytransparenter border-secondary text-textlight text-left"}>Running
                    </div>
                </div>
                <div className={"flex-none w-[200px] text-textmedium"}>{props.nodeSummary.environment}</div>
                <div className={"flex-none w-[200px] text-textmedium"}>
                    <Tooltip delayDuration={30}>
                        <TooltipTrigger>
                            <Progress value={cpuUtil} className="w-[100px] border border-red"/>
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className={"flex flex-col gap-2"}>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>CPU Utilisation:</div>
                                    <div>{cpuUtil.toFixed(2)}%</div>
                                </div>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Host Capacity:</div>
                                    <div>{getCPUAvailable()} cores</div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </div>
                <div className={"flex-none w-[200px] text-textmedium"}>
                    <Tooltip delayDuration={30}>
                        <TooltipTrigger>
                            <Progress value={memoryUtil} className="w-[100px] border border-red"/>
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className={"flex flex-col gap-2"}>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Memory Utilisation (Total - Available)/Total:</div>
                                    <div>{memoryUtil.toFixed(2)}%</div>
                                </div>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Host Capacity:</div>
                                    <div>{getMemoryAvailable()}</div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </div>
                {props.nodeSummary.creationTimestamp !== undefined && <div
                    className={"flex items-center h-full leading-4 grow shrink text-nowrap text-textmedium"}>{hdate.prettyPrint(new Date(props.nodeSummary.creationTimestamp * 1000), {showTime: true})}</div>}
            </div>}
        </Drawer.Trigger>
        <Drawer.Portal>
            <Drawer.Content
                onInteractOutside={() => setOpenDialog(false)}
                className={`select-text flex flex-col rounded-t-[10px] h-full w-2/3 mt-12 fixed bottom-0 right-0`}>
                <HostDetailsSideView node={node} k8sNode={k8sNode}/>
            </Drawer.Content>
        </Drawer.Portal>
    </Drawer.Root>
}


function HostDetailsSideView(props: { node?: Node, k8sNode?: V1Node }) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [currentTab, setCurrentTab] = useState<string>("hostinfo");
    const [pods, setPods] = React.useState<GetPodsResponse>();
    const debouncedGetPods = useDebouncedCallback(getPods, 10);
    const [getPodsAbortController, setGetPodsAbortController] = useState(new AbortController());

    if (props.node === undefined) {
        return <div></div>
    }
    if (props.k8sNode === undefined) {
        return <div></div>
    }

    useEffect(() => {
        if (props.node === undefined) {
            return
        }
        debouncedGetPods(timeRange, [props.node.environment], setPods, getPodsAbortController, setGetPodsAbortController, "", props.node.name);
    }, [timeRange, props.node]);


    return <div data-vaul-no-drag className={"h-full bg-backgrounddark border-l"}>
        <div className={"h-full flex flex-col mx-8 py-4 gap-4"}>
            <HostTitle node={props.node} k8sNode={props.k8sNode}/>
            {/*// @ts-ignore*/}
            <Tabs disabledTabClassName={"hidden"}
                  onValueChange={(val) => setCurrentTab(val)}
                  value={currentTab}
                  defaultValue="metadata"
                  className="w-full flex flex-col grow shrink min-h-0 min-w-0">
                <TabsList className="w-full flex-none mb-4 grid grid-cols-5 grid-rows-1 border">
                    <TabsTrigger value="hostinfo">Host Info</TabsTrigger>
                    <TabsTrigger value="metrics">Host Metrics</TabsTrigger>
                    <TabsTrigger value="pods">Pods</TabsTrigger>
                    <TabsTrigger value="podMetrics">Pod Metrics</TabsTrigger>
                    <TabsTrigger value="yaml">Yaml</TabsTrigger>
                </TabsList>
                <div className={"flex grow shrink justify-start min-h-0 min-w-0 overflow-y-auto no-scrollbar"}>
                    <TabsContent
                        className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto "}
                        value="hostinfo">
                        <div>
                            <div className={"mt-4 flex flex-col gap-4"}>
                                <HostInfo node={props.node} parsedNode={props.k8sNode}/>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent
                        className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto no-scrollbar"}
                        value="metrics">
                        <div>
                            <HostMetrics node={props.node}/>
                        </div>
                    </TabsContent>
                    <TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0"} value="pods">
                        <div>
                            {pods !== undefined &&
                                <PodsTable minimal={true} nodeName={props.node.name} isAggregated={false}
                                           podResponse={pods}/>
                            }
                        </div>
                    </TabsContent>
                    <TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0"} value="podMetrics">
                        <div>
                            {pods !== undefined &&
                                <MetricsPanel
                                    filters={new Map()}
                                    nodeName={props.node.name}
                                    title={"Aggregated Pod Metrics"}
                                    tooltipText={"This panel shows aggregated metrics for all containers in the selected service. For more detailed\n" +
                                        "                information, drill into individual pods."}
                                />
                            }
                        </div>
                    </TabsContent>
                    <TabsContent
                        className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto no-scrollbar"}
                        value="yaml">
                        <div>
                            <div className={"mt-4 flex flex-col gap-4"}>
                                <PrettyYamlViewer yamlString={props.node !== undefined ? props.node.yaml : ""}/>
                            </div>
                        </div>
                    </TabsContent>
                </div>
            </Tabs>
        </div>
    </div>
}


function HostTitle(props: { node?: Node, k8sNode?: V1Node }) {
    if (props.node === undefined) {
        return
    }
    if (props.k8sNode === undefined) {
        return
    }

    return <div className={"flex flex-col"}>
        <div className={"flex w-full justify-between items-center"}>
            <div className={"flex gap-2 items-center"}>
                <div className={"text-textmedium text-lg font-bold text-center"}>{props.node.name}</div>
                <CopyToClipboardButton text={props.node.name}/>
            </div>
            <div
                className={"text-white bg-secondarytransparenter border rounded border-secondarytransparent px-2 py-1"}>Running
            </div>
        </div>
        <div className={"flex items-center justify-start gap-2"}>
            <div className={"text-textdark"}>Cluster:</div>
            <div
                className={"text-textmedium"}>{props.node.environment}</div>
        </div>
    </div>
}

function HostInfo(props: { parsedNode?: V1Node, node?: Node }) {
    if (props.parsedNode === undefined) {
        return <div></div>
    }
    if (props.node === undefined) {
        return <div></div>
    }
    const nodeInfo = props.parsedNode.status?.nodeInfo;
    const [isHostSpecOpen, setIsHostSpecOpen] = useState<boolean>(true);
    const copyableValueStyle = "border rounded bg-backgroundlight px-2 py-1 flex items-center gap-1"
    const entryTitleStyle = "font-semibold text-textdark  w-[200px]"
    const [isHostAliasesOpen, setIsHostAliasesOpen] = useState<boolean>(true);
    const addresses = new Map<string, string>();
    if (props.parsedNode.status !== undefined && props.parsedNode.status.addresses !== undefined) {
        props.parsedNode.status.addresses.forEach((address) => {
            addresses.set(address.type, address.address)
        })
    }
    const capacity: Map<string, string> = new Map(Object.entries(props.parsedNode.status?.capacity ? props.parsedNode.status.capacity : {}))
    const [isCapacitySpecOpen, setIsCapacitySpecOpen] = useState<boolean>(true);

    const allocable: Map<string, string> = new Map(Object.entries(props.parsedNode.status?.allocatable ? props.parsedNode.status.allocatable : {}))
    const [isAllocableSpecOpen, setIsAllocableSpecOpen] = useState<boolean>(true);

    // k8sNode.
    return <div className={"flex flex-col gap-4"}>
        {addresses !== undefined && addresses.size > 0 && <div className={"flex"}>
            <Collapsible open={isHostAliasesOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsHostAliasesOpen(!isHostAliasesOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isHostAliasesOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isHostAliasesOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Host Aliases</div>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(addresses).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key}:</div>
                                <div
                                    className={copyableValueStyle}> {value} <CopyToClipboardButton
                                    text={value}/></div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {nodeInfo !== undefined && <div className={"flex"}>
            <Collapsible open={isHostSpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsHostSpecOpen(!isHostSpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isHostSpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isHostSpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div>Host Specification</div>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Operating System:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.operatingSystem} <CopyToClipboardButton
                                text={nodeInfo.operatingSystem}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> OS Image:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.osImage} <CopyToClipboardButton
                                text={nodeInfo.osImage}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Architecture:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.architecture} <CopyToClipboardButton
                                text={nodeInfo.architecture}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kernel Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kernelVersion} <CopyToClipboardButton
                                text={nodeInfo.kernelVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Machine ID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.machineID} <CopyToClipboardButton
                                text={nodeInfo.machineID}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Boot ID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.bootID} <CopyToClipboardButton
                                text={nodeInfo.bootID}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Container Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.containerRuntimeVersion}
                                <CopyToClipboardButton text={nodeInfo.containerRuntimeVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kubelet Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kubeletVersion} <CopyToClipboardButton
                                text={nodeInfo.kubeletVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kube Proxy Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kubeProxyVersion} <CopyToClipboardButton
                                text={nodeInfo.kubeProxyVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> System UUID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.systemUUID} <CopyToClipboardButton
                                text={nodeInfo.systemUUID}/></div>

                        </div>
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {capacity !== undefined && capacity.size > 0 && <div className={"flex"}>
            <Collapsible open={isCapacitySpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsCapacitySpecOpen(!isCapacitySpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isCapacitySpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isCapacitySpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Host Capacity</div>
                        <Tooltip delayDuration={20}>
                            <TooltipTrigger>
                                <InfoIcon className={"text-textdark w-4 h-4 hover:text-primary"}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"}
                                            className={"bg-backgroundmedium border rounded px-2 py-1 font-normal"}>The
                                fields in the capacity block indicate the total amount of resources that a Node
                                has.</TooltipContent>
                        </Tooltip>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(capacity).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key[0].toUpperCase() + key.slice(1)}:</div>
                                <div
                                    className={copyableValueStyle}> {value}</div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {allocable !== undefined && allocable.size > 0 && <div className={"flex"}>
            <Collapsible open={isAllocableSpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsAllocableSpecOpen(!isAllocableSpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isAllocableSpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isAllocableSpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Allocable Resources</div>
                        <Tooltip delayDuration={20}>
                            <TooltipTrigger>
                                <InfoIcon className={"text-textdark w-4 h-4 hover:text-primary"}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"}
                                            className={"bg-backgroundmedium border rounded px-2 py-1 font-normal"}>The
                                allocatable block indicates the amount of resources on a Node that is available to
                                be
                                consumed by normal Pods.</TooltipContent>
                        </Tooltip>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(allocable).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key[0].toUpperCase() + key.slice(1)}:</div>
                                <div
                                    className={copyableValueStyle}> {value}</div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
    </div>
}

function generateSplits(splits: string[] | undefined) {
    let newSplits = splits || [];
    newSplits = [...newSplits]
    if (newSplits.length == 0) {
        newSplits.push("kubernetes.io/hostname")
    }
    return newSplits
}

function generateFilters(filters: Map<string, string[]> | undefined, node: Node | undefined) {
    let newFilters = filters || new Map()
    newFilters = new Map(newFilters)
    if (node !== undefined) {
        newFilters.set("kubernetes.io/hostname", [node.name])
    }
    return newFilters
}

function HostMetrics(props: {
    node?: Node,
    filters?: Map<string, string[]>,
    splits?: string[],
    excludeFilters?: Map<string, string[]>
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [filters, setFilters] = useState(() => generateFilters(props.filters, props.node))
    const [excludeFilters, setExcludeFilters] = useState(props.excludeFilters || new Map())
    const [splits, setSplits] = useState(() => generateSplits(props.splits))
    const [range, setRange] = useState(timeRange.getStartEnd())

    useEffect(() => {
        let startEnd = timeRange.getStartEnd();
        if (startEnd[0] != range[0] || startEnd[1] != range[1]) {
            setRange(startEnd)
        }
    }, [timeRange]);

    useEffect(() => {
        let newFilters = generateFilters(props.filters, props.node)
        if (JSON.stringify(newFilters, dashboardJsonReplacer) != JSON.stringify(filters, dashboardJsonReplacer)) {
            setFilters(new Map(newFilters))
        }
    }, [props.filters, props.node]);

    useEffect(() => {
        if (JSON.stringify(props.excludeFilters) != JSON.stringify(excludeFilters)) {
            setExcludeFilters(new Map(props.excludeFilters) || new Map())
        }
    }, [props.excludeFilters]);

    useEffect(() => {
        let newSplits = generateSplits(props.splits)
        if (JSON.stringify(newSplits) != JSON.stringify(splits)) {
            setSplits(newSplits)
        }
    }, [props.splits])


    return (
        <div className={"flex flex-col space-y-8 mb-4"}>
            <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto"}>
                <MetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_cpu_usage_seconds_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"sum"}
                    type={ChartType.Line}
                    title={`CPU Usage - Seconds`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={new Map(excludeFilters).set("mode", ["idle", "iowait"])}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"container_net_tcp_active_connections"}
                    aggregation={"sum"}
                    type={ChartType.Line}
                    title={`Open TCP Connections`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_memory_total_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Total - Bytes`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_memory_available_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Available - Bytes`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_memory_free_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Free - Bytes`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_memory_cached_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Cached - Bytes`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_disk_read_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Read`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_resources_disk_written_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Written`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                {/*<MetoroMetricsChart*/}
                {/*    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}*/}
                {/*    hideOnNoData={true}*/}
                {/*    startTime={Math.floor(range[0].getTime() / 1000)}*/}
                {/*    endTime={Math.floor(range[1].getTime() / 1000)}*/}
                {/*    metricName={"node_resources_disk_used_bytes"}*/}
                {/*    aggregation={"max"}*/}
                {/*    type={ChartType.Line}*/}
                {/*    title={`Disk Usage`}*/}
                {/*    splits={splits}*/}
                {/*    filters={filters}*/}
                {/*    styling={{borderless: true}}*/}
                {/*    metricType={MetricType.Metric}*/}
                {/*    functions={[]}*/}
                {/*/>*/}
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_net_received_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Received`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(range[0].getTime() / 1000)}
                    endTime={Math.floor(range[1].getTime() / 1000)}
                    metricName={"node_net_transmitted_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Transmitted`}
                    splits={splits}
                    filters={filters}
                    excludeFilters={excludeFilters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
            </div>
        </div>
    )
}


export default Infrastructure;
