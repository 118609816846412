import GuestGuard from 'guards/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import AuthLogin from 'pages/auth/LoginPage';
import PasswordReset from 'pages/auth/PasswordReset';
import {Toaster} from "../components/ui/toaster";

const LoginRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <CommonLayout/>
                </GuestGuard>
            ),
            children: [
                {
                    path: 'login',
                    element: <AuthLogin isRegister={false}/>
                },
                {
                    path: 'register',
                    children: [
                        {
                            path: '',
                            element: <AuthLogin isRegister={true}/>
                        }
                    ]
                },
                {
                    path: 'reset-password',
                    element: <PasswordReset/>
                },
            ]
        }
    ]
};

export default LoginRoutes;
