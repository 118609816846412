import React, {useEffect} from "react";
import useAuth from "../hooks/useAuth";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {useLocation} from "react-router-dom";
import axios from "../utility/customAxios";
import {useCookies} from "react-cookie";

function AwsMarketplaceRedirect() {
    const {isLoggedIn} = useAuth();
    const navigate = usePreserveQueryParamsNavigate();
    const location = useLocation();
    const [cookies, setCookies] = useCookies(['aws_marketplace_customer_id']);

    // If they are not logged in, redirect them to the login page to authenticate, the login handler will then deal with
    // the aws signup
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', {
                state: {
                    from: location.pathname
                },
                replace: true
            });
            return;
        }
        // They are logged in, then we need to make a call to the backend to finish the redirect
        const awsCustomerIdToken = cookies.aws_marketplace_customer_id ? cookies.aws_marketplace_customer_id as string: "";
        if (awsCustomerIdToken === "") {
            navigate('/billing', {replace: true});
            return
        } else {
            axios.post('/api/v1/awsmarketplace/finish-register', {
                awsMarketplaceCustomerIdToken: awsCustomerIdToken,
            }).then((response) => {
                // Redirect to the billing page
                navigate('/billing', {replace: true});
            })
        }
    }, []);

    return <div>
    </div>
}

export default AwsMarketplaceRedirect;