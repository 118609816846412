import {useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "../utility/customAxios";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import logoIcon from "../assets/images/logoColour.png";
import pagerDutyLogo from "../assets/images/pagerDuty.png";
import {Popover, PopoverContent, PopoverTrigger} from "../components/ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../components/ui/command";
import {Button} from "../components/ui/button";
import {XIcon} from "lucide-react";

function PagerDutyRedirect() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = React.useState(false);
    const [selectedServices, setSelectedServices] = React.useState<PagerDutyService[]>([])
    const [allServices, setAllServices] = React.useState<PagerDutyService[]>([])
    const [isConnected, setIsConnected] = React.useState(false)
    const [isIntegrationAdded, setIsIntegrationAdded] = React.useState(false)
    const navigate = usePreserveQueryParamsNavigate();

    useEffect(() => {
        if (isIntegrationAdded) {
            axios.get("/api/v1/pagerDuty/services").then((response) => {
                setAllServices(response.data.services)
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [isIntegrationAdded]);

    useEffect(() => {
        let code = searchParams.get("code");
        let state = searchParams.get("state");

        if (code && state) {
            // Make a request to the backend to drop off the pagerduty code
            // and state
            axios.post("/api/v1/pagerDuty/token", {
                code: code,
                state: state
            }).then((response) => {
                setIsIntegrationAdded(true)
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [searchParams]);

    useEffect(() => {
        if (isConnected) {
            axios.post("/api/v1/pagerDuty/selectedServices",
                {services: selectedServices}
            ).then((response) => {
                // Redirect to the dashboard
                navigate("/settings")
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [isConnected]);


    return <div
        className="container relative h-screen flex items-center justify-center lg:max-w-none lg:px-0 lg:grid-cols-2 bg-background">
        <div
            className="p-32  rounded border border-border flex-col justify-center items-center gap-16 inline-flex">
            <div className="mx-auto flex w-full flex-col justify-center space-y-6">
                <div className="flex space-y-2 text-center justify-center">
                    <div className={"flex flex-none justify-center"}>
                        <img src={logoIcon} alt="Metoro" className="w-24 h-24"/>
                    </div>
                    <div className={"flex items-center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="64" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             className={"lucide lucide-chevrons-left-right-ellipsis text-border"}>
                            <path d="m18 8 4 4-4 4"/>
                            <path d="m6 8-4 4 4 4"/>
                            <path d="M8 12h.01"/>
                            <path d="M12 12h.01"/>
                            <path d="M16 12h.01"/>
                        </svg>
                    </div>
                    <img src={pagerDutyLogo} alt={"PagerDuty"} className={"w-24 h-24 mx-3"}/>
                </div>
                <div className={"text-textmedium text-3xl font-semibold text-center"}> Connect Metoro and PagerDuty
                </div>
                <div className={"text-textmedium text-xl text-center"}> Select services to integrate with Metoro
                </div>
                <div>
                    <PagerDutyServices allServices={allServices} open={open} setOpen={setOpen} selectedServices={selectedServices}
                                       setSelectedServices={setSelectedServices}/>
                </div>
                <div className={"flex"}>
                    <Button
                        onClick={() => {
                            setIsConnected(true)
                        }}
                        className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight rounded hover:border-primaryhover hover:text-white cursor-pointer"}>
                        Connect
                    </Button>
                </div>
            </div>
        </div>

    </div>
}

interface PagerDutyService {
    id: string
    name: string
}


function PagerDutyServices(props: {
    allServices: PagerDutyService[]
    open: boolean
    setOpen: (open: boolean) => void
    selectedServices: PagerDutyService[]
    setSelectedServices: (services: PagerDutyService[]) => void
}) {
    return <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
        <div
            className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
            PagerDuty Services
        </div>
        <Popover open={props.open} modal={true}>
            <PopoverTrigger asChild className={"flex grow"}>
                <div className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}
                     onClick={() => props.setOpen(true)}>
                    {props.selectedServices.length == 0 && <div className={"text-textmedium"}>
                        Select...
                    </div>}
                    {props.selectedServices.length > 0 && <div className={"flex space-x-2 text-textmedium"}>
                        {props.selectedServices.map((service, index) => {
                            return <ServicePill service={service} onRemove={(service) => {
                                const newSelectedServices = props.selectedServices.filter((selectedService) => service.id !== selectedService.id);
                                props.setSelectedServices(newSelectedServices)
                            }}/>
                        })}
                    </div>}
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"} avoidCollisions={true}
                            className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
            >
                <Command onBlur={() => {
                    props.setOpen(false)
                }}>
                    <CommandInput id={"free_text_input2"}
                                  placeholder={"Search for a PagerDuty Service..."}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty className={"m-16 text-center text-textdark"}>
                            No PagerDuty Services found. Please go to your PagerDuty account and create a service.
                        </CommandEmpty>
                        <CommandGroup>
                            {
                                props.allServices.map((service, index) => {
                                    if (props.selectedServices.find((selectedService) => selectedService.id == service.id)) {
                                        return null;
                                    }
                                    return <CommandItem
                                        className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                        key={index}
                                        onSelect={() => {
                                            props.setSelectedServices([...props.selectedServices, service])
                                            props.setOpen(false)
                                        }}>
                                        {service.name}
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}

function ServicePill (props: {
    service: PagerDutyService
    onRemove: (service: PagerDutyService) => void
}) {
    return <div className="group justify-start items-start flex hover:cursor-pointer">
        <div
            className="p-2 bg-backgrounddark rounded-tl rounded-bl border justify-start items-start flex">
            <div
                className="text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]">{props.service.name}
            </div>
        </div>
        <div
            onClick={() => {
                props.onRemove(props.service);
            }}
            className="h-8 pt-[3px] bg-backgroundlight hover:bg-primary hidden group-hover:block group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textmedium">
            <XIcon/>
        </div>
    </div>;
}

export default PagerDutyRedirect;
export { PagerDutyServices };