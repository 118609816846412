import {createSlice} from '@reduxjs/toolkit'
import {TimeRange} from "../../types/time";

const timeRangeSlice = createSlice({
    name: 'timerange',
    initialState: {
        value: new TimeRange("15m")
    },
    reducers: {
        set: (state, action) => {
            // Only change if the value is different
            let shouldChange = false
            if  (state.value.last === undefined && action.payload.last !== undefined) {
                shouldChange = true
            }
            if (state.value.last !== undefined && action.payload.last === undefined) {
                shouldChange = true
            }
            if (state.value.start === undefined && action.payload.start !== undefined) {
                shouldChange = true
            }
            if (state.value.start !== undefined && action.payload.start === undefined) {
                shouldChange = true
            }
            if (state.value.end === undefined && action.payload.end !== undefined) {
                shouldChange = true
            }
            if (state.value.end !== undefined && action.payload.end === undefined) {
                shouldChange = true
            }
            if (state.value.last !== undefined && action.payload.last !== undefined && state.value.last !== action.payload.last) {
                shouldChange = true
            }
            if (state.value.start !== undefined && action.payload.start !== undefined && state.value.start.getTime() !== action.payload.start.getTime()) {
                shouldChange = true
            }
            if (state.value.end !== undefined && action.payload.end !== undefined && state.value.end.getTime() !== action.payload.end.getTime()) {
                shouldChange = true
            }

            if (shouldChange) {
                state.value = action.payload
            }
        }
    },
    selectors: {
        getTimeRange: (state) => state.value
    }
})

export const { set } = timeRangeSlice.actions

export default timeRangeSlice