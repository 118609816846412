import { createSlice } from '@reduxjs/toolkit'
import {TimeRange} from "../../types/time";

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: {
        isOnboarded: true,
    },
    reducers: {
        set: (state, action) => {
            state.isOnboarded = action.payload
        }
    },
    selectors: {
        getOnboarded: (state) => state.isOnboarded
    }
})

export const { set } = onboardingSlice.actions

export default onboardingSlice