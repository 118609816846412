import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import {ChevronDown} from "lucide-react";
import React, {useEffect, useState} from "react";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";

export function ClusterSelector() {
    const [searchParams, setSearchParams] = useSearchParams();
    let params = new URLSearchParams(searchParams);
    const [clusters, setClusters] = useState<string[]>([])
    const [selectedCluster, setSelectedCluster] = useState<string>(params.get("environment") || "")


    useEffect(() => {
        // Fetch environments/clusters
        axios.get("/api/v1/environments").then((response) => {
            setClusters(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        let params = new URLSearchParams(searchParams);
        params.set("environment", selectedCluster);
        setSearchParams(params);
    }, [selectedCluster])

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div
                    className={"flex rounded border border-border bg-backgroundmedium justify-center items-center hover:cursor-pointer hover:border-primary"}>
                    <div
                        className={"text-textlight px-2 text border border-secondary bg-secondarytransparenter"}>Cluster
                    </div>
                    <div className={"border-l flex bg-backgroundlight justify-center items-center px-2 h-full"}>
                        <div
                            className={"text-textmedium bg-backgroundlight pr-2"}>
                            {selectedCluster === "" ? "All Clusters" : selectedCluster}
                        </div>
                        <ChevronDown
                            className={"bg-backgroundlight text-textmedium h-4 w-4"}/>
                    </div>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-backgroundmedium rounded">
                <DropdownMenuLabel className={"text-textmedium"}>Clusters</DropdownMenuLabel>
                <DropdownMenuSeparator className={"bg-border"}/>
                <DropdownMenuGroup>
                    <DropdownMenuItem key={"all"} className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"}
                                      onClick={() => {
                                          setSelectedCluster("")}}>
                        All Clusters
                    </DropdownMenuItem>
                    {clusters.map((cluster) => (
                        <DropdownMenuItem key={cluster} className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"
                        + (selectedCluster === cluster ? " bg-backgroundlight" : "")}
                                         onClick={() => setSelectedCluster(cluster)}>
                            {cluster}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
