import {CopyIcon, CheckIcon} from "lucide-react";
import {useState} from "react";
import {Tooltip, TooltipContent, TooltipTrigger} from "./ui/tooltip";


function CopyToClipboardButton(props: {
    title?: string;
    buttonColour?: string;
    size?: string;
    text: string;
    borderStyle?: string;
}) {
    const [isCopied, setIsCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(props.text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    let buttonColour = props.buttonColour ? props.buttonColour : "text-textdark";
    let size = props.size ? props.size : "w-4 h-4";
    let borderStyle = props.borderStyle ? props.borderStyle: "";

    return (
        <Tooltip delayDuration={10}>
            <TooltipTrigger>
                <div
                    className={borderStyle + (props.title ? " flex gap-1": "")}>
                    {props.title && <div className={"text-white hover:text-textmedium"}
                                          onClick={() => handleCopy()}>{props.title}</div>}
                    {!isCopied &&
                        <CopyIcon className={(props.title ? "" : "hover:cursor-pointer hover:text-textmedium ") + buttonColour + " " + size}
                                  onClick={() => handleCopy()}/>}
                    {isCopied && <CheckIcon className={buttonColour + " " + size}/>}
                </div>
            </TooltipTrigger>
            <TooltipContent side={"right"} className={"bg-backgrounddark text-textmedium border rounded px-2 py-1"}>
                {isCopied ? "Copied!" : "Copy to clipboard"}
            </TooltipContent>
        </Tooltip>

    );
}

export default CopyToClipboardButton;