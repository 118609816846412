import React from "react";
import {AlertCircleIcon, LineChartIcon} from "lucide-react";
import {Separator} from "../ui/separator";
import {cn, usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import humanFormat from "human-format";
import Header from "../Card/Header";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip";

interface ServicePanelProps {
    serviceName: string;
    displayName: string;
    numAnomalies: number;
    numAlerts: number;
    serviceP50Nanos: number;
    serviceP95Nanos: number;
    serviceP99Nanos: number;
    service500s: number;
    service400s: number;
    requestCount: number;
}

export const shortEnglishHumanizer = (ms: number, decimals?: number) => {
    let numberOfDecimals = decimals !== undefined ? decimals : 2;
    if (ms < 1000) {
        return `${ms.toFixed(numberOfDecimals)} ms`;
    }
    if (ms < 60_000) {
        return `${(ms / 1000).toFixed(numberOfDecimals)} s`;
    }
    if (ms < 3_600_000) {
        return `${(ms / 60000).toFixed(numberOfDecimals)} m`;
    }
    if (ms < 86_400_000) {
        return `${(ms / 3_600_000).toFixed(numberOfDecimals)} h`;
    }
    return `${(ms / 86_400_000).toFixed(numberOfDecimals)} d`;
}



function Latency(props: { ServiceP50Nanos: number, ServiceP95Nanos: number, ServiceP99Nanos: number }) {
    let p50Color = "text-green-500";
    if (props.ServiceP50Nanos > 200_000_000) {
        p50Color = "text-orange-500";
    }
    if (props.ServiceP50Nanos > 300_000_000) {
        p50Color = "text-red-500";
    }
    let p95Color = "text-green-500";
    if (props.ServiceP95Nanos >= 200_000_000) {
        p95Color = "text-orange-500";
    }
    if (props.ServiceP95Nanos >= 300_000_000) {
        p95Color = "text-red-500";
    }

    let p99Color = "text-green-500";
    if (props.ServiceP99Nanos >= 200_000_000) {
        p99Color = "text-orange-500";
    }
    if (props.ServiceP99Nanos >= 300_000_000) {
        p99Color = "text-red-500";
    }

    const parsedP50 = shortEnglishHumanizer(props.ServiceP50Nanos / 1_000_000);
    const parsedP95 = shortEnglishHumanizer(props.ServiceP95Nanos / 1_000_000);
    const parsedP99 = shortEnglishHumanizer(props.ServiceP99Nanos / 1_000_000);

    return <div>
        <div className={"p-4"}>
            <div className={"text-textmedium text-xl font-semibold leading-7"}>
                Latency
            </div>
            <div className={"flex space-x-4 pt-2"}>
                <div>
                    <div className={"text-textdark text-sm font-normal leading-normal"}>P50</div>
                    <div className={cn(p50Color, "text-lg font-semibold leading-7")}>{parsedP50}</div>
                </div>
                <div>
                    <div className={"text-textdark text-sm font-normal leading-normal"}>P95</div>
                    <div className={cn(p95Color, "text-lg font-semibold leading-7")}>{parsedP95}</div>
                </div>
                <div>
                    <div className={"text-textdark text-sm font-normal leading-normal"}>P99</div>
                    <div className={cn(p99Color, "text-lg font-semibold leading-7")}>{parsedP99}</div>
                </div>
            </div>
        </div>
        <Separator/>
    </div>
}

function Requests(props: { Service500s: number, Service400s: number, ServiceRequestCount: number }) {
    const humanized500s = humanFormat(props.Service500s);
    const humanized400s = humanFormat(props.Service400s);
    const humanizedRequestCount = humanFormat(props.ServiceRequestCount)

    let fiveHundredsColor = "text-green-500";
    if (props.Service500s > 0) {
        fiveHundredsColor = "text-red-500";
    }
    let fourHundredsColor = "text-green-500";
    if (props.Service400s > 0) {
        fourHundredsColor = "text-red-500";
    }

    return <div>
        <div className={"p-4"}>
            <div className={"text-textmedium text-xl font-semibold leading-7"}>
                Requests
            </div>
            <div className={"flex space-x-10 pt-2"}>
                <div>
                    <div className={"text-slate-500 text-sm font-normal leading-normal"}>Requests</div>
                    <div className={cn("text-textmedium text-lg font-semibold leading-7")}>{humanizedRequestCount}</div>
                </div>
                <div>
                    <div className={"text-slate-500 text-sm font-normal leading-normal"}>5XXs</div>
                    <div className={cn(fiveHundredsColor, "text-lg font-semibold leading-7")}>{humanized500s}</div>
                </div>
                <div>
                    <div className={"text-slate-500 text-sm font-normal leading-normal"}>4XXs</div>
                    <div className={cn(fourHundredsColor, "text-lg font-semibold leading-7")}>{humanized400s}</div>
                </div>
            </div>
        </div>
    </div>
}

function ServicePanel(props: ServicePanelProps) {
    const navigate = usePreserveQueryParamsNavigate();

    return <div
        onClick={() => {
            navigate(`/service?service=${props.serviceName}`);
        }}
        className={"rounded border w-full h-[422px] bg-backgroundmedium hover:border-primary hover:bg-primarytransparent hover:cursor-pointer"}>
        <Header title={props.displayName} serviceName={props.serviceName}/>
        <Issues
            NumAlerts={props.numAlerts}
            NumAnomalies={props.numAnomalies}
            Service500s={props.service500s}
            ServiceRequestCount={props.requestCount}
        />
        {props.requestCount > 0 &&
            <div>
                <Latency ServiceP50Nanos={props.serviceP50Nanos} ServiceP95Nanos={props.serviceP95Nanos}
                         ServiceP99Nanos={props.serviceP99Nanos}/>
                <Requests ServiceRequestCount={props.requestCount} Service500s={props.service500s}
                          Service400s={props.service400s}/>
            </div>}
        {props.requestCount <= 0 && <NoRequestsMessage/>}

    </div>
}


function Issues(props: { NumAnomalies: number, NumAlerts: number, Service500s?: number, ServiceRequestCount?: number }) {
    const errorRate = props.Service500s && props.ServiceRequestCount
        ? (props.Service500s / props.ServiceRequestCount) * 100
        : 0;
    const isUnhealthy = errorRate > 2;

    return <div>
        <div className={"p-4"}>
            <div className={"text-textmedium text-xl font-semibold leading-7"}>
                Status
            </div>
            <div className={"flex pt-2"}>
                {
                    props.NumAlerts > 0 ? <div
                        className={"rounded-lg p-2 border-red-500 border text-red-500 text-base font-normal leading-normal flex space-x-2 ml-4"}>
                        <div className={"w-6 h-6"}><AlertCircleIcon/></div>
                        <div>
                            {props.NumAlerts} alerts firing
                        </div>
                    </div> : <div></div>
                }
                {
                    props.NumAlerts > 0 ? <div
                            className={"rounded-lg p-2 border-orange-400 border text-orange-400 text-base font-normal leading-normal flex space-x-2 ml-4"}>
                            <div className={"w-6 h-6"}><LineChartIcon/></div>
                            <div>
                                {props.NumAnomalies} anomalies
                            </div>
                        </div>
                        : <div></div>
                }
                {
                    props.NumAlerts == 0 && props.NumAnomalies == 0 ? 
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger>
                                    <div className={cn(
                                        "rounded-lg p-2 border text-base font-normal leading-normal",
                                        isUnhealthy 
                                            ? "border-red-500 text-red-500" 
                                            : "border-green-500 text-green-500"
                                    )}>
                                        {isUnhealthy ? "Unhealthy" : "Healthy"}
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent className={"text-textlight"}>
                                    <p>
                                        {isUnhealthy 
                                            ? `Service is unhealthy: 5xx error rate is ${errorRate.toFixed(2)}% (threshold: 2%)`
                                            : `Service is healthy: 5xx error rate is ${errorRate.toFixed(2)}%`
                                        }
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        : <div></div>
                }
            </div>
        </div>
        <Separator/>
    </div>
}

function NoRequestsMessage() {
    return <div>
        <div className={"p-8"}>
            <div className={"text-textdark text-lg font-semibold leading-7 text-center"}>
                No requests were made to this service in the specified time period
            </div>
        </div>
    </div>
}


export default ServicePanel;